<admin-page-wrapper>
  <div class="admin-registration-form">
    <h2 class="form-title">Crie a featFlag</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="admin-registration-form__form-container">
        <div class="admin-registration-form__form-column">
          <div class="admin-registration-form__form-field-group">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Nome da FeatFlag</mat-label>
              <input matInput placeholder="Digite seu nome" required formControlName="nome">
            </mat-form-field>
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Icone da FeatFlag</mat-label>
              <input matInput placeholder="Digite seu nome" required formControlName="icone">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="admin-registration-form__form-actions">
        <button mat-flat-button color="primary" class="button-action" type="submit" [disabled]="registering">
          <mat-spinner *ngIf="registering" style="display: inline-block;" [diameter]="24" mode="indeterminate"></mat-spinner>
          {{ 'Criar' }}
        </button>
      </div>
    </form>
  </div>
</admin-page-wrapper>
  