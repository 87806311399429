import { Component, OnInit } from '@angular/core';
import { onSideNavChange, animateText } from '../animations/animations';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
// import { SidenavService } from '../services/sidenav.service'

interface Menus {
  link: string;
  name: string;
  icon: string;
  open: boolean;
  submenus: Array<any>;
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [onSideNavChange, animateText],
})
export class LeftMenuComponent implements OnInit {
  // public linkText: boolean = false;

  public menus: Menus[] = [
    {
      name: 'Usuários do APP',
      link: '',
      open: false,
      icon: 'person',
      submenus: [
        {
          name: 'Cadastrar usuários',
          link: '/admin',
        },
        {
          name: 'Usuários Cadastrados',
          link: '/admin/history',
        },
      ],
    },
    {
      name: 'Quiz',
      link: '/admin/remote-control',
      icon: 'quiz',
      open: false,
      submenus: [],
    },
    {
      name: 'Eventos',
      link: 'some-link',
      icon: 'event',
      open: false,
      submenus: [
        {
          name: 'Novo evento',
          link: '/admin/event-add',
        },
        {
          name: 'Eventos Cadastrados',
          link: '/admin/events',
        },
      ],
    },
    {
      name: 'Painel admin',
      link: '',
      icon: 'analytics',
      open: false,
      submenus: [
        {
          name: 'Registro de ações',
          link: '/admin/app-log-painel',
        },
        {
          name: 'Lista de FeatFlags',
          link: '/admin/app-listar-feat-flag',
        },
        {
          name: 'Criar nova FeatFlag',
          link: '/admin/app-criar-feat-flag',
        },
        {
          name: 'Controle de FeatFlag',
          link: '/admin/app-adicionar-feat-flag-user',
        },
      ],
    },
    {
      name: 'Controle de Takoletas',
      link: '',
      icon: 'paid',
      open: false,
      submenus: [
        {
          name: 'Adicionar pontuação',
          link: '/admin/app-adicionar-takoletas',
        },
      ],
    },
    {
      name: 'Brindes',
      link: '',
      icon: 'card_giftcard',
      open: false,
      submenus: [
        {
          name: 'Novos Pedidos',
          link: '/admin/app-brindes-order',
        },
        {
          name: 'Pedidos Realizados',
          link: '/admin/app-brindes-list',
        },
        {
          name: 'Cadastrar Brindes',
          link: '/admin/app-brindes-add',
        },
        {
          name: 'Brindes Cadastrados',
          link: '/admin/app-brindes',
        },
      ],
    },
    {
      name: 'Banners',
      link: '',
      icon: 'wallpaper',
      open: false,
      submenus: [
        {
          name: 'Cadastrar Banner',
          link: '/admin/app-cadastrar-banner',
        },
        {
          name: 'Banners Cadastrados',
          link: '/admin/app-banner-list',
        },
      ],
    },
    {
      name: 'Controle de Push',
      link: '',
      icon: 'send_to_mobile',
      open: false,
      submenus: [
        {
          name: 'Envio de push',
          link: '/admin/app-push-controller',
        },
      ],
    },
  ];

  constructor(public authService: AuthService, public router: Router) {}

  loggedUser;
  currentRoute;
  isAdmin: boolean = false;

  ngOnInit() {
    this.getAdmin();
    this.loggedUser = this.authService.loggedUser.name;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        console.log(event);
      }
    });
    this.currentRoute = this.router.url;
    console.log(this.router.url);
  }

  async getAdmin() {
    try {
      await this.authService.verificaAdiministrador();
      this.isAdmin = this.authService.isAdimin;
      if (!this.isAdmin) {
        this.menus = this.menus.filter((menu) => menu.name !== 'Painel admin');
      }
    } catch (error) {}
  }

  openSubmenu(page) {
    page.open = !page.open;
  }

  checkMenuSelected(menu) {
    return (
      menu.link == this.currentRoute ||
      menu.submenus.map((m) => m.link).indexOf(this.currentRoute) != -1
    );
  }
}
