import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class AlertInterceptor implements HttpInterceptor {


  constructor() { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.method === 'POST') {
      return next.handle(request).pipe(
        tap(
          (event) => {
            if (
              event instanceof HttpResponse &&
              event.status === 200
            ) {
              if (typeof event.body.result === "string") {
                Swal.fire({
                  icon: event.body.success ? 'success' : 'error',
                  text: event.body.result,
                });
              } else if (typeof event.body.result === "object") {
                Swal.fire({
                  icon: event.body.success ? 'success' : 'error',
                  text: event.body.result.message,
                });
              }
            }
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              Swal.fire({
                icon: 'error',
                text: error.error.result || 'Erro no servidor.',
              });
            }
          }
        )
      );
    } else {
      return next.handle(request);
    }
  }
}
