<admin-page-wrapper>
  <div class="admin-registration-form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="admin-registration-form__form-container">
        <div class="admin-registration-form__form-column">
          <div class="admin-registration-form__form-field-group">
            <div class="image-container">
              <img *ngIf="imageBase64 != undefined" [src]="imageBase64" alt="Imagem selecionada">
              <img *ngIf="imageBase64 == undefined" [src]="form.get('imageLink').value" alt="Imagem selecionada">
              <button type="button" (click)="uploadImage()" class="btn-upload">Img</button>
            </div>
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Nome do brinde</mat-label>
              <input matInput placeholder="Digite seu nome" required formControlName="nome">
            </mat-form-field>
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Descrição do brinde</mat-label>
              <input matInput rows="5" formControlName="descricao" required>
            </mat-form-field>
          </div>
          <div class="admin-registration-form__form-field-group">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Takoletas:</mat-label>
              <input type="number" matInput rows="5" formControlName="takoletasResgate" required>
            </mat-form-field>
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label for="numero">Ordem:</mat-label>
              <input type="number" matInput rows="5" formControlName="ordem" required id="numero" [(ngModel)]="numeroInserido" required>
            </mat-form-field>
          </div>
          <div class="admin-registration-form__form-field admin-registration-form__form-field-radio-button">
            <label id="radio-btn-perfil">Brinde está ativo:</label>
            <mat-radio-group required color="primary" aria-labelledby="radio-btn-perfil" formControlName="ativo">
              <mat-radio-button [value]="true">Sim</mat-radio-button>
              <mat-radio-button [value]="false">Não</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="admin-registration-form__form-actions">
        <button mat-flat-button color="primary" class="button-action" type="submit" [disabled]="registering">
          <mat-spinner *ngIf="registering" style="display: inline-block;" [diameter]="24" mode="indeterminate"></mat-spinner>
          {{ idBrinde ? 'Atualizar' : 'Cadastrar'}}
        </button>
        <button mat-stroked-button color="secondary" class="button-action" type="button" (click)="resetForm()"
          [disabled]="registering">
          <mat-icon>replay</mat-icon>
          Limpar
        </button>
      </div>
    </form>
  </div>
</admin-page-wrapper>
