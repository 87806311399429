import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() sidenav: MatSidenav

  title:string;

  constructor(private route:ActivatedRoute, private router:Router) { 
    route.url.subscribe(() => {
      this.title = route.snapshot.firstChild.data.title;
     });
  }

  ngOnInit() {
  }

}