import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeatFlagService } from 'src/app/services/feat-flag.service';

@Component({
  selector: 'app-flags-pendentes',
  templateUrl: './flags-pendentes.component.html',
  styleUrls: ['./flags-pendentes.component.scss'],
})
export class FlagsPendentesComponent implements OnInit {
  flagsUserPend: any[];
  userAplicador: any;
  dialogRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userAplicador: any;
      flagsUserPend: any[];
    },
    private service: FeatFlagService,
    private router: Router
  ) {
    this.flagsUserPend = data.flagsUserPend;
    this.userAplicador = data.userAplicador;
  }

  ngOnInit(): void {}

  async onSubmit(idUsuario, idFeatFlag) {
    try {
      const result = await this.service.AdicionarFeatFlagUser(
        idUsuario,
        idFeatFlag
      );

      if (result) {
        this.dialogRef.close(true);
      }
    } catch (error) {}
  }
}
