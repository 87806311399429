<admin-page-wrapper>
  <mat-form-field class="search-field">
    <input matInput placeholder="Código do Aplicador" [(ngModel)]="searchQuery" class="rounded-input">
    <button mat-button matSuffix color="warn" (click)="buscar()" class="search-button">Buscar</button>
  </mat-form-field>
  <div style="display: flex; flex-wrap: wrap;">
    <div *ngIf="userAplicador" class="user-card">
      <mat-card>
        <mat-card-content>
          <div class="user-info">
            <div>
              <mat-card-title>{{ userAplicador.nome }}</mat-card-title>
              <mat-card-subtitle>{{ userAplicador.cpf }}</mat-card-subtitle>
              <mat-card-subtitle>Codigo: {{ userAplicador.codigoIdentificacao }}</mat-card-subtitle>
            </div>
            <button mat-icon-button color="primary" (click)="open()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div *ngIf="flagsUser.length > 0" class="flags-section">
            <h3>Clique na featFlag que deseja excluir:</h3>
            <div class="flags-grid" *ngIf="userAplicador">
              <div *ngFor="let flag of flagsUser" class="flag-item">
                <div class="flag-wrapper">
                  <button mat-icon-button class="icon-button-flag"
                    (click)="openDeleteConfirmationModal(userAplicador._Id, flag._Id)">
                    <mat-icon class="icon">{{ flag.icone }}</mat-icon>
                  </button>
                  <p class="flag-text">{{ flag.nome }}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br><br>


  <h2>Inserção em massa de Feat Flags</h2>
  <div style="display: flex;">
    <mat-select style="margin-right: 10px;" placeholder="Selecione a Feat Flag Base" id="selectBase" name="selectBase"
    [(ngModel)]="selectedFeatFlagBase" (selectionChange)="onBaseSelectionChange()">
    <mat-option *ngFor="let featFlag of listaDeFeatFlag" [value]="featFlag">
      <mat-label>{{ featFlag.nome }}</mat-label>
    </mat-option>
  </mat-select>
  

    <div style="margin-right: 10px; align-self: center;">➔</div>
    <mat-select style="margin-right: 10px;" placeholder="Selecione a Feat Flag a ser adicionada" id="selectToAdd"
      name="selectToAdd" [(ngModel)]="selectedFeatFlagToAdd" (selectionChange)="onAddSelectionChange()">
      <mat-option *ngFor="let featFlag of filteredFeatFlags" [value]="featFlag">
        {{ featFlag.nome }}
      </mat-option>
    </mat-select>

    <button mat-button matSuffix color="warn" (click)="aplicar(selectedFeatFlagBase, selectedFeatFlagBase, selectedFeatFlagToAdd)"
  class="search-button" [disabled]="isButtonDisabled">Aplicar</button>

  </div>


</admin-page-wrapper>
<ng-template #modal>
  <div class="modal-container">
    <h2>Selecione a FeatFlag que deseja:</h2>
    <div class="flags-grid">
      <div *ngFor="let flag of flagsUserPend" class="flag-item">
        <div class="flag-wrapper">
          <button mat-icon-button class="icon-button-flag" (click)="onSubmit(userAplicador._Id, flag._Id)">
            <mat-icon class="icon">{{ flag.icone }}</mat-icon>
          </button>
          <p class="flag-text">{{ flag.nome }}</p>
        </div>
      </div>
    </div>
    <button mat-icon-button class="close-button" (click)="fecharModal()">
      <mat-icon class="icon">close</mat-icon>
    </button>
  </div>
</ng-template>