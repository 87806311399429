<div class="quiz-container">
  <div class="title">
    <h2 >Bem-vindo ao evento</h2>
    <br>
    <span class="white-card primary ">{{eventData?.nome}}</span>
  </div>

  <h1>
    Bora preparar o carro <br />
    <strong>para a grande corrida!!!</strong>
  </h1>
  <div class="quiz-container__content">
    <div class="quiz-container__content__qrcode">
      <span>Baixe o APP</span>
      <img src="assets/images/qr-code-home.png" alt="qr code" />
    </div>
    <div class="quiz-container__content__marketplace">
      <div>
        <span>/ TAKAO</span>
      </div>
      <img src="assets/images/google_play.png" alt="baixe o app na playstore" />
      <img
        src="assets/images/loja-apple.png"
        alt="baixe o app na apple store"
      />
    </div>
    <div class="quiz-container__content__count">
      <div *ngIf="displaySpinner$ | async; else spinner">
        <span
          ><timer-count-down class="white-card primary timer-count-down"
            [startDate]="startDate"
            [idEvent]="eventData?.idEvento"
            (onTimeEnd)="startDate = null"
          ></timer-count-down
        ></span>
      </div>
      <ng-template #spinner>
        <mat-spinner></mat-spinner>
      </ng-template>

      <span>Em breve novo quiz</span>
    </div>
  </div>
</div>
