import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

export interface EventData {
  idEvento: string;
  nome: string;
  codigoEvento: string;
  dataInicio: string;
  dataFim: string;
  textoApp: string;
  possuiQuiz: boolean;
  status: boolean;
  endereco: object;
  linkInscricao: string
  cidade
}

interface RequestResult<T> {
  success: boolean;
  data?: T | null;
}

@Injectable({
  providedIn: 'root'
})
export class EventService { 

  constructor(
    private readonly http: HttpClient,
    private readonly firestore: AngularFirestore
  ) { }

  async listAll(): Promise<any> {
    
    try {
      const { apiBaseUrl } = environment;
      const url = `${apiBaseUrl}/Evento/ListAll`;
      const response = await this.http
        .get(url)
        .toPromise<any>();    
      return response?.result || [];
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async getEvento(idEvento): Promise<any> {
    
    try {
      const { apiBaseUrl } = environment;
      const url = `${apiBaseUrl}/Evento/GetEvento`;

      let httpOptions = {
        headers: new HttpHeaders({
          'idEvento': idEvento,
        })
      }
      const response = await this.http
        .get(url, httpOptions)
        .toPromise<any>();    
      return response?.result || [];
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async updateEvent(data: EventData): Promise<RequestResult<void>> {
    const url = environment.apiBaseUrl + '/Evento/Update';
    try {
      const postData = {
        IdEvento: data.idEvento,
        Nome: data.nome,
        codigoEvento: data.codigoEvento,
        PrevisaoInicio: data.dataInicio,
        PrevisaoFim: data.dataFim,
        TextoBotao: data.textoApp,
        QuizAtivo: Boolean(Number(data.possuiQuiz)),
        IsAtivo : Boolean(Number(data.status)),
        Endereco: data.endereco,
        Linkinscricao: data.linkInscricao,
        Cidade: data.cidade
      };
      const response = await this.http.post(url, postData).toPromise<any>();   
      if (!Boolean(Number(data.status))) {
        this.firestore.collection('feiras').ref.doc(data.idEvento).set({iniciado: false}, {merge: true})
      }

      if (response.success === false){  
        return { success: false, data: response?.result?.message };
      }       
     
      
      
      return { success: true, data: response?.result?.message };
    } catch (err) {   
      return { success: false, data: null };
    }
  
  }

  async newEvent(data: EventData): Promise<RequestResult<void>> {
    const url = environment.apiBaseUrl + '/Evento/NewEvent';
    try {
      const postData = {
        IdEvento: data.idEvento,
        Nome: data.nome,
        codigoEvento: data.codigoEvento,
        PrevisaoInicio: data.dataInicio,
        PrevisaoFim: data.dataFim,
        TextoBotao: data.textoApp,
        QuizAtivo: Boolean(Number(data.possuiQuiz)),
        IsAtivo : Boolean(Number(data.status)),
        Endereco: data.endereco,
        Cidade: data.cidade,
        Linkinscricao: data.linkInscricao
      };
      const response = await this.http.post(url, postData).toPromise<any>();   
      if (response.success === false){       
        return { success: false, data: response?.result?.message };
      }       
     
      return { success: true, data: response?.result?.message };
    } catch (err) {   
      return { success: false, data: null };
    }

  
  }


}

