import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AreaAtuacaoService } from 'src/app/services/area-atuacao.service';
import { UserRegistrationService } from 'src/app/services/user-registration.service';
import { QrcodeDialogComponent } from './qrcode-dialog/qrcode-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RegistrationHistoryService } from 'src/app/services/registration-history.service';
import { MatSnackBar } from "@angular/material/snack-bar";


export class CustomValidators {
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      if (sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value) {
        targetCtrl.setErrors({ mismatch: true });
      } else {
        targetCtrl.setErrors(null);
      }

      return null;
    };
  }
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  userForm = new FormGroup({
    cpf: new FormControl(''),
    dataNascimento: new FormControl(''),
    nome: new FormControl(''),
    telefone: new FormControl(''),
    telefoneConfirmacao: new FormControl(''),
    perfil: new FormControl(3),
    nomeMecanica: new FormControl(''),
    cnpj: new FormControl(''),
    senha: new FormControl(''),
    endereco: new FormGroup({
      cep: new FormControl(''),
      logradouro: new FormControl(''),
      bairro: new FormControl(''),
      numero: new FormControl(''),
      complemento: new FormControl(''),
    }),
  }, [CustomValidators.MatchValidator('telefone', 'telefoneConfirmacao')]);

  @ViewChild('userFormDirective') private userFormDirective: NgForm;

  /**
   * Current loading state of register form.
   */
  registering = false;

  /**
   * Loading state of address auto-complete
   */
  gettingAddress = false;

  constructor(
    public readonly areaAtuacao: AreaAtuacaoService,
    private readonly userRegistration: UserRegistrationService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.generatePassword();

    this.userForm
      .get('endereco.cep')
      .valueChanges
      .pipe(
        debounceTime(750),
        distinctUntilChanged()
      )
      .subscribe(this.getAddress.bind(this))
  }

  get shouldDisplayQRCode(): boolean {
    return (
      !!this.userForm.controls.cpf.value &&
      !!this.userForm.controls.password.value
    );
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  async onSubmit(): Promise<void> {

    if (this.userForm.invalid) return;
    this.registering = true;
    const formData = this.userForm.value;
    const result = await this.userRegistration.signup(formData);
    this.registering = false;
    console.log("result ", result)
    if (result.success) {
      this.snackBar.open("Usuário salvo com sucesso.", '', { duration: 2000 });
      this.resetForm();
      this.displayQrcode(this.userRegistration.createQrcodeValue(formData));
    } else {
      var error: string = String(result['data']);
      this.snackBar.open(error, '', { duration: 8000, panelClass: ["custom-color-snack-error"], });
    }
  }

  generatePassword() {
    var chars =
      '0123456789';
    var passwordLength = 5;
    var password = '';
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    this.userForm.controls.senha.setValue(password);
  }

  resetForm() {
    this.userForm.reset();
    this.userFormDirective.resetForm();
    this.generatePassword();
    this.userForm.controls.perfil.setValue(3);
  }

  displayQrcode(qrcodeValue: string) {
    this.dialog.open(QrcodeDialogComponent, {
      data: {
        qrcodeValue,
        onNewRegistrationClick: () => {
          this.resetForm();
          this.dialog.closeAll();
        },
      },
      panelClass: 'qrcode-dialog__panel-container'
    });
  }

  async getAddress(value: string): Promise<void> {
    if (!value) return;
    this.gettingAddress = true;
    const result = await this.userRegistration.getAddressByZipcode(value);
    this.gettingAddress = false;
    if (result.success) {
      const { logradouro, bairro, complemento } = result.data;
      this.userForm.get('endereco').patchValue({
        logradouro,
        bairro,
        complemento,
      })
    } else {
      this.userForm.get('endereco').patchValue({
        logradouro: '',
        bairro: '',
        complemento: '',
        numero: '',
      })
    }
  }
}
