import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

interface Resposta {
  descricao: string;
  respostaCerta: boolean;
}

export interface Pergunta {
  id: string;
  titulo: string;
  pontuacao: number;
  respostas: Resposta[];
}

interface Resposta {
  descricao: string;
  respostaCerta: boolean;
}

export interface Pergunta {
  id: string;
  titulo: string;
  pontuacao: number;
  respostas: Resposta[];
}

export interface CreateNewQuizParams {
  should_finish?: boolean;
  idEvent?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  activeQuestion = new BehaviorSubject<Pergunta | null>(null);
  channel = new BroadcastChannel('app-routes');
  selectedService = ''

  SetSelectedService(ss) {
    this.selectedService = ss
  }

  _perguntas: Pergunta[] = [];
  _firestoreSubscription: Subscription | null = null;

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly http: HttpClient
  ) { }

  fetchNextAward(): Observable<any> {
    const { apiBaseUrl } = environment;
    return this.http.get<any>(`${apiBaseUrl}/Evento/GetEvento`);
  }

  async fetchQuestions({ token, idEvento }): Promise<any> {
    try {
      const { apiBaseUrl } = environment;
      let url = `${apiBaseUrl}/Evento/BuscarRodadaPergunta`;
      const response = await this.http
        .get(url, {
          headers: {
            idEvento: idEvento
          }
        })
        .toPromise<any>();

      if (response && response.result && !response.result.perguntas) {
        url = `${apiBaseUrl}/Evento/NovaRodadaQuiz`;
        const responseNext = await this.http
          .get(url, {
            headers: {
              idEvento: idEvento
            },
          })
          .toPromise<any>();

        url = `${apiBaseUrl}/Evento/BuscarRodadaPergunta`;
        const response = await this.http
          .get(url, {
            headers: {
              idEvento: idEvento
            },
          })
          .toPromise<any>();
      }


      return response?.result?.perguntas || [];
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async fetchWinners(_token, idEvento): Promise<any> {
    const headers = {
      idEvento: idEvento,

    };
    try {
      const { apiBaseUrl } = environment;
      const url = `${apiBaseUrl}/Evento/FinalizarRodada`;
      const response = await this.http
        .get(url, {
          headers,
        })
        .toPromise<any>();
      return response?.result || [];
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  async createNewQuiz(params?: CreateNewQuizParams): Promise<void> {
    const { apiBaseUrl } = environment;
    const headers = {
      idEvento: params?.idEvent
    };
    const should_finish = params?.should_finish ?? true;
    if (should_finish)
      await this.finishCurrentQuiz();
    await this.http
      .get(`${apiBaseUrl}/Evento/NovaRodadaQuiz`, { headers })
      .toPromise<any>();
  }

  async finishCurrentQuiz(): Promise<void> {
    const { apiBaseUrl } = environment;
    const headers = {};
    return this.http
      .get(`${apiBaseUrl}/Evento/FinalizarRodada`, { headers })
      .toPromise<any>();
  }

  subscribe(selectedEvent) {
    this._firestoreSubscription = this.firestore
      .collection('feiras')
      .doc<any>(selectedEvent)
      .valueChanges()
      .subscribe((data) => {
        if (data?.ultimo_status === 'rodada_iniciada') this._novaRodada(data);
        if (data?.ultimo_status === 'rodada_finalizada')
          this.activeQuestion.next(null);
      });
  }

  unsubscribe() {
    this._firestoreSubscription?.unsubscribe?.();
  }

  async update(selectedEvent, data: any): Promise<void> {
    if (data)
      this.firestore.collection('feiras').doc(selectedEvent).update(data);
  }

  private async _novaRodada(data: any): Promise<void> {
    if (!data) return;
    const { pergunta_atual, id_evento: idEvento, token_evento: token } = data;
    this._perguntas = (await this.fetchQuestions({ idEvento, token })) || [];
    if (pergunta_atual > 0 && this._perguntas.length) {
      if (pergunta_atual > this._perguntas.length) {
        this.activeQuestion.next(null); // começar nova rodada
        this.update(idEvento, { ir_para_tela: 'podium' });
      } else {
        this.activeQuestion.next(this._perguntas[pergunta_atual - 1]); // proxima pergunta
      }
    }
  }
}
