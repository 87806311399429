<admin-page-wrapper>
  <img src="/assets/images/logo-takao.png" alt="Logo Takao" class="logo" />

  <div class="table__empty-state" *ngIf="!(registrationHistory.history | async).length">
    <h4>Não há registro aqui 🙁</h4>
  </div>

  <mat-form-field appearance="outline" *ngIf="(registrationHistory.history | async).length" class="search-field">
    <mat-label>Pesquisar</mat-label>
    <input matInput placeholder="Pesquisar por nome ou CPF" [(ngModel)]="search">
  </mat-form-field>

  <div class="table__container" *ngIf="(registrationHistory.history | async).length">
    <table mat-table [dataSource]="(registrationHistory.history | async) | historyfilter:search">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex flex-row items-center gap-1">
            <strong>{{element.nome}}</strong>
            <button type="button" color="primary" mat-icon-button (click)="displayQrcode(element)">
              <mat-icon>visibility</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{element.cpf | mask:'000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="pass">
        <th mat-header-cell *matHeaderCellDef>Senha</th>
        <td mat-cell *matCellDef="let element">
          <button class="password-button" type="button" (click)="togglePassword(element.cpf)">
            <span *ngIf="visiblePassword.includes(element.cpf)">{{element.senha}}</span>
            <span *ngIf="!visiblePassword.includes(element.cpf)">******</span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef align="right">Criado em</th>
        <td mat-cell *matCellDef="let element" align="right">{{formatDate(element.createdAt)}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</admin-page-wrapper>
