import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TakoletasService } from 'src/app/services/takoletas.service';

@Component({
  selector: 'app-adicionar-takoletas',
  templateUrl: './adicionar-takoletas.component.html',
  styleUrls: ['./adicionar-takoletas.component.scss'],
})
export class AdicionarTakoletasComponent implements OnInit {
  @ViewChild('modal') modal: TemplateRef<any>;
  formulario: FormGroup;
  registering = false;

  constructor(
    private service: TakoletasService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formulario = this.fb.group({
      codAplicador: ['', Validators.required],
      motivo: ['', Validators.required],
      takoletas: ['', [Validators.required, Validators.min(1)]],
      adicionarParaTodos: [false]
    });


    this.formulario.get('adicionarParaTodos')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.formulario.get('codAplicador')?.disable();
        this.formulario.get('codAplicador')?.reset();
      } else {
        this.formulario.get('codAplicador')?.enable();
      }
    });
  }

  async creditarTakoletas() {
    if (this.formulario.invalid) {
      return;
    }
  
    const { codAplicador, motivo, takoletas, adicionarParaTodos } = this.formulario.value;
    this.registering = true;
  
    try {
      if (adicionarParaTodos) {
        await this.service.creditarTakoletas("", motivo, takoletas);
      } else {
        await this.service.creditarTakoletas(codAplicador, motivo, takoletas);
      }
  
      this.formulario.reset({ adicionarParaTodos: false });
      this.registering = false;
    } catch (error) {
      this.registering = false;
      console.error("Erro ao creditar takoletas:", error);
    }
  }
  
}
