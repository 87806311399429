import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaAtuacaoService } from 'src/app/services/area-atuacao.service';
import { UserRegistrationService } from 'src/app/services/user-registration.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RegistrationHistoryService } from 'src/app/services/registration-history.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { EventService } from 'src/app/services/event.service';


export class CustomValidators {
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      if (sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value) {
        targetCtrl.setErrors({ mismatch: true });
      } else {
        targetCtrl.setErrors(null);
      }

      return null;
    };
  }
}

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.scss'],
})
export class EventAddComponent implements OnInit {
  form = new FormGroup({
    idEvento: new FormControl(''),
    nome: new FormControl(''),
    codigoEvento: new FormControl(''),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    textoApp: new FormControl(''),
    possuiQuiz: new FormControl(1),
    status: new FormControl(1),
    endereco: new FormGroup({
        logradouro: new FormControl(''),
        numero: new FormControl(''),
        complemento: new FormControl(''),
        bairro: new FormControl(''),
        cep: new FormControl(''),
        cidade: new FormControl(''),
        estado: new FormControl('')
    }),
    linkInscricao: new FormControl(''),
    
});

  idEvento: any;

  @ViewChild('formDirective') private formDirective: NgForm;

  /**
   * Current loading state of register form.
   */
  registering = false;

  /**
   * Loading state of address auto-complete
   */
  gettingAddress = false;

  constructor(    
    private readonly eventService: EventService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.idEvento =  this.activeRoute.snapshot.params['idEvento'];
    if(this.idEvento != undefined) {
      this.eventService.getEvento(this.idEvento).then((response) => {     
        this.form.controls.idEvento.setValue(this.idEvento);
        this.form.controls.nome.setValue(response.nome);
        this.form.controls.codigoEvento.setValue(response.codigoEvento);
        this.form.controls.dataInicio.setValue(response.previsaoInicio.replace("Z", ".000"));
        this.form.controls.dataFim.setValue(response.previsaoFim.replace("Z", ".000"));
        this.form.controls.textoApp.setValue(response.textoBotao);
        this.form.controls.possuiQuiz.setValue(response.quizAtivo ? 1 : 0);
        this.form.controls.status.setValue(response.eventoAtivo ? 1 : 0);
        //this.form.controls.cidade.setValue(response.cidade);
        this.form.controls.linkInscricao.setValue(response.linkInscricao);
        this.form.get('endereco.logradouro').setValue(response.endereco.logradouro);
        this.form.get('endereco.numero').setValue(response.endereco.numero);
        this.form.get('endereco.cep').setValue(response.endereco.cep);
        this.form.get('endereco.bairro').setValue(response.endereco.bairro);
        this.form.get('endereco.complemento').setValue(response.endereco.complemento);
        this.form.get('endereco.cidade').setValue(response.endereco.cidade);
        this.form.get('endereco.estado').setValue(response.endereco.estado);
      });
    }
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  async onSubmit(): Promise<void> {
    if (this.form.invalid) return;
    this.registering = true;
    const formData = this.form.value;

    let result: any;

    if (this.idEvento != undefined) {
      result = await this.eventService.updateEvent(formData);
    } else {
      result = await this.eventService.newEvent(formData);
    }

    this.registering = false;
    if (result.success) {
      this.router.navigateByUrl('admin/events');
      this.snackBar.open("Evento salvo com sucesso.", '', { duration: 2000 });
      this.resetForm();

    } else {
      var error: string = String(result['data']);
      this.snackBar.open(error, '', { duration: 8000, panelClass: ["custom-color-snack-error"], });
    }
  }

  resetForm() {
    this.form.reset();
    this.formDirective.resetForm();
    this.form.controls.possuiQuiz.setValue(1);
    this.form.controls.status.setValue(1);
  }

}
