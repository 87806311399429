<admin-page-wrapper>
  <div class="remote-control remote-control__container">
    <div class="loading-overlay" *ngIf="isLoading">
      <mat-spinner color="white"></mat-spinner>
    </div>
    <ng-container *ngIf="!noEvents" >

      <div class="remote-control__section flex flex-col gap-4">
      <h4>Selecione o evento:</h4>
        <div class="event-definer">
          <select (change)="setSelectedEvent($event.target.value)">
            <option *ngFor="let firebaseEvent of firebaseEvents" [value]="firebaseEvent.idEvento"  [selected]="firebaseEvent.idEvento == quizService.selectedService">
              {{firebaseEvent.nome}}
            </option>
          </select>
        <a target="_blank" [routerLink]="['/']" [queryParams]="{event: selectedEvent}">Abrir o telão</a>
        </div>
      </div>

      <div class="remote-control__section flex flex-col gap-4">
      <h4>Status do quiz</h4>
        <div class="flex flex-row gap-4 justify-between">
          <button type="button" class="flex-1 danger" (click)="_confirm(_iniciar)" [disabled]="isBooted">
          Começar o quiz
          </button>
          <button type="button" class="flex-1 danger" (click)="_confirm(_resetar)" [disabled]="!isBooted">
          Finalizar o quiz
          </button>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="noEvents" >
      <div class="remote-control__section flex flex-col gap-4">
        <h1 style="text-align: center;">
          Nenhum evento ativo no momento.
        </h1>
        <button type="button" class="flex-1 danger" (click)="ngOnInit()">⟳ Atualizar</button>
      </div>
      </ng-container>

    <ng-container *ngIf="isBooted">
      <div class="remote-control__section flex flex-col gap-4">
        <h4>Nova rodada</h4>
        <div class="info-container" *ngIf="!!startDate">
          Iniciando em
          <strong><timer-count-down class="timer" [startDate]="startDate" [idEvent]="selectedEvent" (onTimeEnd)="startDate = null"></timer-count-down></strong>
        </div>
        <div class="flex flex-row gap-4 justify-between">
          <button type="button" class="flex-1" (click)="_confirm(_novaRodada, 5)"
            [disabled]="!isBooted || this.isQuizzInProgress">
            +5min
          </button>
          <button type="button" class="flex-1" (click)="_confirm(_novaRodada, 10)"
            [disabled]="!isBooted || this.isQuizzInProgress">
            +10min
          </button>
          <button type="button" class="flex-1" (click)="_confirm(_novaRodada, 15)"
            [disabled]="!isBooted || this.isQuizzInProgress">
            +15min
          </button>
        </div>
        <div class="flex flex-row gap-4">
          <button type="button" class="flex-1  danger" (click)="_confirm(_novaRodada)"
            [disabled]="!isBooted || this.isQuizzInProgress">
            Iniciar a rodada
          </button>
          <button type="button" class="danger flex-1" (click)="_confirm(_encerrarRodada)" [disabled]="
              !isBooted || (!this.isQuizzInProgress && !this.isQuizzScheduled)
            ">
            Encerrar a rodada
          </button>
        </div>
      </div>

      <div class="remote-control__section flex flex-col gap-4">
        <h4>Perguntas</h4>
        <div class="flex flex-row gap-4 justify-between">
          <button type="button" class="flex-1" (click)="_confirm(_proximaPergunta)"
            [disabled]="!isBooted || !this.isQuizzInProgress">
            {{ questionButtonText }}
          </button>
        </div>
      </div>

      <div class="remote-control__section flex flex-col gap-4">
        <h4>Controle de telas</h4>
        <div class="flex flex-col gap-4 justify-between">
          <button type="button" class="flex-1" (click)="_confirm(_irParaTela, '')">
            Ir para a abertura
          </button>
          <button type="button" class="flex-1" (click)="_confirm(_irParaTela, 'instructions')">
            Ir para as instruções
          </button>
          <button type="button" class="flex-1" (click)="_confirm(_irParaTela, 'quiz')">
            Ir para o quiz
          </button>
          <button type="button" class="flex-1" (click)="_confirm(_irParaTela, 'podium')">
            Ir para o podium
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</admin-page-wrapper>
