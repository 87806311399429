import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { format, parseISO } from 'date-fns';

import { RegistrationHistoryService } from 'src/app/services/registration-history.service';
import { UserRegistrationService } from 'src/app/services/user-registration.service';
import { QrcodeDialogComponent } from '../admin/qrcode-dialog/qrcode-dialog.component';

@Component({
  selector: 'app-registration-history',
  templateUrl: './registration-history.component.html',
  styleUrls: ['./registration-history.component.scss'],
})
export class RegistrationHistoryComponent {
  displayedColumns: string[] = ['name', 'cpf', 'pass', 'date'];
  visiblePassword: string[] = [];

  constructor(
    public readonly registrationHistory: RegistrationHistoryService,
    private readonly dialog: MatDialog,
    private readonly userRegistration: UserRegistrationService
  ) {}

  displayQrcode(data: any) {
    this.dialog.open(QrcodeDialogComponent, {
      data: {
        qrcodeValue: this.userRegistration.createQrcodeValue(data),
        showRegisterButton: false,
      },
      panelClass: 'qrcode-dialog__panel-container',
    });
  }

  togglePassword(cpf: string) {
    if (this.visiblePassword.includes(cpf))
      this.visiblePassword = this.visiblePassword.filter(_cpf => _cpf !== cpf);
    else
      this.visiblePassword.push(cpf);
  }

  formatDate(date: string) {
    return format(parseISO(date), "dd/MM/yyyy 'às' HH:mm");
  }
}
