import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatFlagService } from 'src/app/services/feat-flag.service';

@Component({
  selector: 'app-criar-feat-flag',
  templateUrl: './criar-feat-flag.component.html',
  styleUrls: ['./criar-feat-flag.component.scss'],
})
export class CriarFeatFlagComponent implements OnInit {
  form = new FormGroup(
    {
      nome: new FormControl(''),
      icone: new FormControl(''),
    },
    {}
  );
  decodedJson: any;
  registering = false;
  featFlag: any;
  idFlag: any;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private service: FeatFlagService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.idFlag = this.activeRoute.snapshot.params['idFlag'];
    if (this.idFlag != undefined) {
      this.form.controls.nome.setValue('');
      this.form.controls.icone.setValue('');
    }
  }

  async onSubmit() {
    if (this.form.valid) {
      let formData = this.form.value;
      try {
        const result = await this.service.insertOneFeatFlag(formData);
        this.router.navigateByUrl('/admin/app-listar-feat-flag');
      } catch (error) {}
    }
  }
}
