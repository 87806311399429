import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'admin-page-wrapper',
  templateUrl: './admin-page-wrapper.component.html',
  styleUrls: ['./admin-page-wrapper.component.scss']
})
export class AdminPageWrapperComponent {
  constructor(private readonly router: Router, private readonly authService: AuthService) { }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  logout() {
    this.authService.signout();
  }
}
