import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BannerService } from 'src/app/services/banner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cadastrar-banner',
  templateUrl: './cadastrar-banner.component.html',
  styleUrls: ['./cadastrar-banner.component.scss'],
})
export class CadastrarBannerComponent implements OnInit {
  numeroInserido: number;
  erro: boolean = false;
  erroMensagem: string = '';
  listaDeRotas = [];

  @ViewChild('formDirective') private formDirective: NgForm;

  //FORMULARIO DO BANNER
  form = new FormGroup(
    {
      _id: new FormControl(''),
      imageLink: new FormControl('', Validators.required),
      nome: new FormControl(''),
      dataInicio: new FormControl(''),
      dataFim: new FormControl(null),
      ordem: new FormControl(),
      route: new FormControl(''),
      featureFlag: new FormControl(''),
      isRoute: new FormControl(true),
    },
    {}
  );
  decodedJson: any;
  registering = false;
  banner: any;
  idBanner: any;
  imageBase64: string;
  isDisabled: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private service: BannerService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.applyFilterOnInit();
    this.idBanner = this.activeRoute.snapshot.params['idBanner'];
    if (this.idBanner != undefined) {
      this.service.getByIdLocal(this.idBanner).then((result) => {
        this.banner = result.result;

        if (this.banner != undefined) {
          const dataInicioFormatada = new Date(this.banner.dataInicio)
            .toISOString()
            .slice(0, 16);
          const dataFimFormatada = this.banner.dataFim
            ? new Date(this.banner.dataFim).toISOString().slice(0, 16)
            : null;
          this.form.controls._id.setValue(this.banner._Id);
          this.form.controls.nome.setValue(this.banner.nome);
          this.form.controls.ordem.setValue(this.banner.ordem);
          this.form.controls.route.setValue(this.banner.route);
          this.form.controls.dataFim.setValue(dataFimFormatada);
          this.form.controls.dataInicio.setValue(dataInicioFormatada);
          this.form.controls.imageLink.setValue(this.banner.imageLink);
          this.form.controls.featureFlag.setValue(this.banner.featureFlag);
        } else {
          this.form.controls._id.setValue('');
          this.form.controls.nome.setValue('');
          this.form.controls.dataInicio.setValue('');
          this.form.controls.dataFim.setValue(null);
          this.form.controls.imageLink.setValue('');
          this.form.controls.ativo.setValue('');
          this.form.controls.ordem.setValue('');
          this.form.controls.route.setValue('');
          this.form.controls.featureFlag.setValue('');
        }
      });
    }
  }

  resetForm() {
    this.form.reset();
    this.formDirective.resetForm();
  }

  async onSubmit() {
    let noImage = true;
    if (this.imageBase64) {
      this.form.controls.imageLink.setValue(this.to64(this.imageBase64));
      noImage = false;
    }
    if (this.form.valid) {
      let formData = this.form.value;
      if (this.idBanner !== undefined) {
        try {
          if (noImage) formData = { ...formData, imageLink: '' };
          const result = await this.service.updateBanner(formData);
          this.router.navigateByUrl('/admin/app-banner-list');
        } catch (error) { }
      } else {
        try {
          const result = await this.service.insertOneBanner(formData);
          this.router.navigateByUrl('/admin/app-banner-list');
        } catch (error) { }
      }
    } else {
      if (this.form.value.imageLink == '') {
        this.snackBar.open('Preencha a imagem do Banner', '', {
          duration: 2000,
        });
      } else {
        this.snackBar.open('Preencha todos os campos', '', { duration: 2000 });
      }
    }
  }

  to64(stringBase: string) {
    return stringBase.replace(/^data:.+;base64,/, '');
  }
  applyFilterOnInit() {

    this.getAll();
  }
  async getAll() {
    try {
      const registrosResponse = await this.service.getAllRoutes();

      this.listaDeRotas = registrosResponse || [];

    } catch (error) {
      console.error('Erro ao obter registros:', error);

    }
  }

  uploadImage() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.imageBase64 = reader.result as string;
        };
        reader.readAsDataURL(file);

        const imageUrl = URL.createObjectURL(file);
        const safeImageUrl: SafeUrl =
          this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }
    };
    input.click();
  }
}
