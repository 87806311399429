<div class="instructions-container">
  <div class="instructions-container__content">
    <div class="instructions-container__content__phone">
      <img class="gif" src="assets/images/instrucao_quiz.gif" alt="app takao" />
      <img src="assets/images/circulo_lateral.png" alt="" />
    </div>

    <div class="instructions-container__content__count">
      <h2 class="event-descripton primary white-card margin-bottom-2">
        {{eventData?.nome}}
      </h2>

      <h1 class="margin-bottom-2">Para jogar <br> Digite no APP<br> o código abaixo:</h1>
<div class="white-card margin-bottom-4">
  <h1 class="bold primary ">{{eventData?.codigoEvento}}
  </h1>
</div>

      <div>
        <div *ngIf="displaySpinner$ | async; else spinner">
          <span
            ><timer-count-down class="white-card primary timer-count-down"
              [startDate]="startDate"
              [idEvent]="eventData?.idEvento"
              (onTimeEnd)="startDate = null"
            ></timer-count-down
          ></span>
        </div>
        <ng-template #spinner>
          <mat-spinner></mat-spinner>
        </ng-template>

        <span class="smaller"
          >Em breve <br />
          novo Quiz</span
        >
      </div>
    </div>
  </div>
</div>
