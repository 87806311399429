<div class="quiz">
  <div class="event-descripton margin-bottom-2" *ngIf="eventData?.nome">
    {{eventData?.nome}} - {{eventData?.codigoEvento}}
  </div>
  <mat-divider></mat-divider>
  <div class="quiz__container" *ngIf="quiz$ | async">

    <h1>{{eventData.perguntaAtual}}) {{ (quiz$ | async).titulo }}</h1>
    <div>
      <ul>
        <li
          *ngFor="
            let option of (quiz$ | async).respostas;
            trackBy: trackByFn;
            let i = index
          "
        >
          <span class="bold">{{ answerLetter(i) }})</span>
          <span>
            {{ option.descricao }}
          </span>
        </li>
      </ul>
      <img src="assets/images/logo_takao.png" alt="" />
    </div>
  </div>
</div>
