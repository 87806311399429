import { Component, OnInit } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { LogPainelService } from 'src/app/services/log-painel.service';

@Component({
  selector: 'app-log-painel',
  templateUrl: './log-painel.component.html',
  styleUrls: ['./log-painel.component.scss'],
})
export class LogPainelComponent implements OnInit {
  filtro = '0';
  valor: string;
  valorData: Date;
  filtroTipoDeRegistro: string;
  filtroInformacoes: string;
  filtroDataRegistro: string;
  filtroNomeUsuario: string;
  loaded: boolean = true;
  registros = [];
  isLoading: boolean = false;


  valorDataFiltro: string = '';

  tiposDeFiltro: any[] = [];

  displayedColumns: string[] = [
    'nomeRegistro',
    'tipoRegistro',
    'mensagemAcao',
    'dataRegistro',
    'nomeUsuario',
  ];

  public nomeUsuario: { name: string, id: string }[] = [];



  constructor(private service: LogPainelService) { }
  ngOnInit(): void {
    this.applyFilterOnInit();
  }

  onFiltroChange(event: any) {
    const filtro = event.target.value;
    const filtroInteiro = parseInt(filtro, 10);
    this.isLoading = true
    this.service.getFilterType(filtroInteiro)
      .then((resultado) => {
        console.log(resultado);
        this.tiposDeFiltro = resultado;
        this.isLoading = false;

      })
      .catch((erro) => {
        console.error('Erro:', erro);
        this.isLoading = false;

      });
  }



  applyFilterOnInit() {
    const filters = {
      filtro: parseInt(this.filtro, 10),
      valor: 0 || 'a',
      valorData: this.valorData || '2012-12-12',
    };

    this.getAll(filters);
  }

  applyFilter() {
    const filters = {
      filtro: parseInt(this.filtro, 10),
      valor: this.valor || 'a',
      valorData: this.valorData || '2012-12-12',
    };

    this.getAll(filters);
  }

  formatDate(date: string) {
    return format(parseISO(date.replace('Z', '')), 'dd/MM/yyyy');
  }

  async getAll(filters) {
    try {
      this.isLoading = true
      const registrosResponse = await this.service.getAllRegistros(filters);

      this.registros = registrosResponse || [];
      this.isLoading = false;

    } catch (error) {
      console.error('Erro ao obter registros:', error);
      this.isLoading = false;

    }
  }
}
