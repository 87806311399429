<div class="sidenav_container" [@onSideNavChange]="'open'">
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">

    <div>
      <mat-nav-list class="left-menu">
        <div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="header empty-header">
          &nbsp;
        </div>
        <div>
          <mat-nav-list class="sub-left-header">
            <!-- <img class="avatar" src="" onerror="this.src='/assets/images/avatar.png';" alt=""> -->
            <span class="name-user">{{loggedUser}}</span>
            <!-- <span class="email-user">email@email.com</span> -->
          </mat-nav-list>
          <hr />
        </div>

          <span mat-list-item *ngFor="let menu of menus" class="menu-item" [routerLink]="menu.link ? [menu.link] : []" [ngStyle]="{color: checkMenuSelected(menu) ? '#c51617' : 'black'}">
            <mat-icon (click)="openSubmenu(menu)">
              {{menu?.icon}}
            </mat-icon>
            <div class="button-wrapper">
              <span (click)="openSubmenu(menu)" >
                {{ menu?.name }}
              </span>
              <div *ngIf="menu?.open" class="sub-items">
                <span [routerLink]="[submenu?.link]" *ngFor="let submenu of menu.submenus" class="submenu" [ngStyle]="{color: submenu?.link == currentRoute ? '#c51617' : 'black'}">
                  - {{ submenu?.name }}
                </span>
              </div>
            </div>
          </span>

          <span mat-list-item class="menu-item" [routerLink]="['logout']">
            <mat-icon>
              logout
            </mat-icon>
            <div class="button-wrapper">
              <span>
                Sair
              </span>
            </div>
          </span>
        </mat-nav-list>
    </div>
  </div>


</div>