<h2>Selecione a FeatFlag que deseja:</h2>
<div class="flags-grid">
  <div *ngFor="let flag of flagsUserPend" class="flag-item">
    <div class="flag-wrapper">
      <button mat-icon-button class="icon-button" (click)="onSubmit(userAplicador._Id, flag._Id)">
        <mat-icon class="icon">{{ flag.icone }}</mat-icon>
      </button>
      <p class="flag-text">{{ flag.nome }}</p>
    </div>
  </div>
</div>
