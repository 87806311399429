import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { QRCodeModule } from 'angularx-qrcode';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuizComponent } from './modules/quiz/quiz.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './modules/admin/admin.component';
import { NgxMaskModule } from 'ngx-mask';
import { LoginComponent } from './modules/login/login.component';
import { IntroComponent } from './modules/intro/intro.component';
import { InstructionsComponent } from './modules/instructions/instructions.component';
import { QrcodeDialogComponent } from './modules/admin/qrcode-dialog/qrcode-dialog.component';
import { RegistrationHistoryComponent } from './modules/registration-history/registration-history.component';
import { AdminPageWrapperComponent } from './components/admin-page-wrapper/admin-page-wrapper.component';
import { RegistrationHistoryPipe } from './services/registration-history.pipe';
import { RemoteControlComponent } from './modules/remote-control/remote-control.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireRemoteConfigModule } from '@angular/fire/remote-config';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { TimerCountDownComponent } from './components/timer-count-down/timer-count-down.component';
import { GridComponent } from './modules/grid/grid.component';
import { PodiumComponent } from './modules/podium/podium.component';
import { LeftMenuComponent } from './template/left-menu/left-menu.component';
import { MenuComponent } from './template/menu/menu.component';
import { HeaderComponent } from './template/header/header.component';
import { EventsComponent } from './modules/events/events.component';
import { EventAddComponent } from './modules/event-add/event-add.component';
import { MatMenuModule } from '@angular/material/menu';
import { LogoutComponent } from './modules/logout/logout.component';
import { SetTokenRequestInterceptor } from './services/set-token-request.interceptor';
import { BrindesComponent } from './modules/brindes/brindes.component';
import { BrindesEditComponent } from './modules/brindes/brindes-edit/brindes-edit/brindes-edit.component';
import { BrindesOrderComponent } from './modules/brindes/brindes-order/brindes-order/brindes-order.component';
import { BrindesListComponent } from './modules/brindes/brindes-list/brindes-list/brindes-list.component';
import { CadastrarBannerComponent } from './modules/banner/cadastrar-banner/cadastrar-banner/cadastrar-banner.component';
import { BannerListComponent } from './modules/banner/banners-list/banner-list/banner-list.component';
import { LogPainelComponent } from './modules/log-painel/log-painel.component';
import { AlertInterceptor } from './services/interceptor_alerts.interceptor';
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm/delete-confirm.component';
import { CriarFeatFlagComponent } from './modules/featFlag/criar-feat-flag/criar-feat-flag.component';
import { AdicionarFeatFlagUserComponent } from './modules/featFlag/adicionar-feat-flag-user/adicionar-feat-flag-user.component';
import { ListarFeatFlagComponent } from './modules/featFlag/listar-feat-flag/listar-feat-flag.component';
import { FlagsPendentesComponent } from './components/flags-pendentes/flags-pendentes.component';
import PushControllerComponent from './modules/push-controller/push-controller.component';
import { AdicionarTakoletasComponent } from './modules/adicionar-takoletas/adicionar-takoletas.component';


@NgModule({
  declarations: [
    AppComponent,
    QuizComponent,
    AdminComponent,
    IntroComponent,
    PodiumComponent,
    LoginComponent,
    AdicionarTakoletasComponent,
    InstructionsComponent,
    QrcodeDialogComponent,
    GridComponent,
    RegistrationHistoryComponent,
    AdminPageWrapperComponent,
    RegistrationHistoryPipe,
    RemoteControlComponent,
    TimerCountDownComponent,
    LeftMenuComponent,
    MenuComponent,
    HeaderComponent,
    EventsComponent,
    EventAddComponent,
    LogoutComponent,
    BrindesComponent,
    BrindesEditComponent,
    BrindesOrderComponent,
    BrindesListComponent,
    CadastrarBannerComponent,
    BannerListComponent,
    LogPainelComponent,
    DeleteConfirmComponent,
    CriarFeatFlagComponent,
    AdicionarFeatFlagUserComponent,
    ListarFeatFlagComponent,
    FlagsPendentesComponent,
    PushControllerComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatMenuModule,
    NgxMaskModule.forRoot(),
    QRCodeModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    AngularFirestoreModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetTokenRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlertInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    console.log('v0.1.0-1622');
  }
}
