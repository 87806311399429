import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private readonly http: HttpClient) { }

  async getAllBanners(): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/Banner/ListAllBanner';
      const response = await this.http.get(url).toPromise<any>();

      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getAllRoutes(): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/PainelApp/ListRoutes';
      const response = await this.http.post(url, {}).toPromise<any>();

      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  getByIdLocal(id) {
    const url = environment.apiBaseUrl + `/Banner/GetByIdBanner?id=${id}`;
    return this.http.get(url).toPromise<any>();
  }

  async insertOneBanner(banner): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/Banner/CriarBanner';
      const response = await this.http.post(url, banner).toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async updateBanner(banner): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/Banner/AtualizarBanner';

      const response = await this.http.post(url, banner).toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async deleteById(idBanner, nome) {
    const url = environment.apiBaseUrl + `/Banner/DeletarBanner`;
    const requestDeletarBanner = {
      idBanner: idBanner,
      nome: nome,
    };

    try {
      const response = await this.http
        .post(url, requestDeletarBanner)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error('Erro ao excluir o banner:', error);
      throw error;
    }
  }
}
