import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  qrcodeValue: string;
  onNewRegistrationClick?: () => void;
  showRegisterButton?: boolean;
}

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QrcodeDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  get showRegisterButton(): boolean {
    return this.data.showRegisterButton ?? true;
  }

  onNewRegistrationClick() {
    this.data?.onNewRegistrationClick?.();
  }
}
