<div class="loading-backdrop" *ngIf="isLoading">
  <div class="loadingio-spinner-rolling-6xgi3puknxi"><div class="ldio-ifpg4ad0ovc">
    <div></div>
  </div></div>
</div>

<style type="text/css">
.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

@keyframes ldio-ifpg4ad0ovc {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-ifpg4ad0ovc div {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 20px solid #e15b64;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-ifpg4ad0ovc div {
  animation: ldio-ifpg4ad0ovc 1s linear infinite;
  top: 100px;
  left: 100px
}
.loadingio-spinner-rolling-6xgi3puknxi {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-ifpg4ad0ovc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ifpg4ad0ovc div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>


<div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="header">
  <span class="title">Acessar o painel do APP</span>
  <span class="spacer"></span>  
</div>

<div class="wrapper">
  <div class="wrapper__content">
    <!-- <mat-card> -->
      <!-- <mat-card-content> -->
        <img src="/assets/images/logo-takao.png" alt="Logo Takao" class="logo" />

        <form [formGroup]="loginForm" (ngSubmit)="onSignin()">
          <div class="wrapper__form-container">
            <mat-form-field class="wrapper__form-field" appearance="outline">
              <mat-label>Usuário</mat-label>
              <input matInput placeholder="Digite o nome de usuário" required formControlName="username">
            </mat-form-field>

            <mat-form-field class="wrapper__form-field" appearance="outline">
              <mat-label>Senha</mat-label>
              <input matInput placeholder="Digite a senha" type="password" required formControlName="password">
            </mat-form-field>
          </div>
          <button mat-raised-button color="primary" class="wrapper__form-submit entrar">Acessar</button>
        </form>

      <!-- </mat-card-content> -->
    <!-- </mat-card> -->
  </div>
</div>
