<div style="display: flex; flex-wrap: wrap;">
    <mat-card  style="margin: 10px; width: 45%;padding-left: 0px;" *ngFor="let pedido of pedidos">
      <mat-card-header class="header">
        <div style="margin-right: auto;">
          <mat-card-title>{{pedido.nome}}</mat-card-title>
          <mat-card-subtitle>{{pedido.codAplicador}}</mat-card-subtitle>
          <mat-card-subtitle>{{pedido.cpf}}</mat-card-subtitle>
          <mat-card-subtitle>{{pedido.telefone}}</mat-card-subtitle>
        </div>
        <div style="margin-left: auto;">
          <mat-card-title style="justify-content: end;display: flex;">{{pedido.enderecoBrinde.logradouro}}, {{pedido.enderecoBrinde.numero}}</mat-card-title>
          <mat-card-title style="justify-content: end;display: flex;">{{pedido.enderecoBrinde.bairro}}, {{pedido.enderecoBrinde.complemento}}</mat-card-title>
          <mat-card-title style="justify-content: end;display: flex;">{{pedido.enderecoBrinde.cidade}} - {{pedido.enderecoBrinde.estado}}</mat-card-title>
          <mat-card-subtitle style="justify-content: end;display: flex;">{{pedido.enderecoBrinde.cep}}</mat-card-subtitle>
        </div>
      </mat-card-header>
      <div mat-card-image></div>
      <mat-card-content style="margin-bottom: 50px;" class="scrollable-content">
        <mat-list style="display: grid; margin-left: 25px;" role="list">
          <mat-list-item *ngFor="let premio of pedido.premioList" role="listitem">
            <div style="display: flex; justify-content: space-between;">
              <div>{{premio.nome}}</div>
              <div style="display: flex;">
                <div style=" padding-right: 25px;">
                    {{premio.tamanho}}
                </div>
                <div style=" padding-right: 15px;">
                  {{premio.quantidade}}
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
      <div class="status-div">
        <mat-form-field class="status-select">
          <mat-label>Status</mat-label>
          <mat-select id="status" [(ngModel)]="pedido.status">
            <mat-option value="Recebido">Recebido</mat-option>
            <mat-option value="Em andamento">Em andamento</mat-option>
            <mat-option value="Enviado">Enviado</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="status-input" *ngIf="pedido.status === 'Enviado'">
          <mat-label for="codRastreio">Código de Rastreio</mat-label>
          <input matInput rows="5" required [(ngModel)]="codRastreio">
        </mat-form-field>
      </div>
      <mat-card-actions style="position: absolute; bottom: 15px; right: 15px;">
        <button style="background: #75626221;" mat-button (click)="alterarStatus(pedido)">Enviar</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <br><br>

  
  