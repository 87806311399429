import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  #isAuthenticated = false;
  // #validPassword = '938375';
  // #validUsername = 'takao';
  loggedUser = {accessToken:'', name:''};
  isAdimin: boolean  = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    // let loggedUser = localStorage.getItem('loggedUser')
    // this.loggedUser = loggedUser ? JSON.parse(loggedUser) : {};
  }

  async verificaAdiministrador() {
    const { apiBaseUrl } = environment;
    try {
      const result = await this.http.post(apiBaseUrl + '/PainelApp/VerificaAdiministrador', {}, {
        headers: { 'Content-Type': 'application/json' }
      }).toPromise();
  
      this.isAdimin = result['result'];
    } catch (error) {
    }
  }

  async signin(userData: any) {
    const { apiBaseUrl } = environment;
    
    let result = await <any>this.http.post( apiBaseUrl + '/PainelApp/Login', JSON.stringify({
      email: userData.username,
      password: userData.password,
    })).toPromise()

    if (result && result.valid) {
      this.#isAuthenticated = true;
      this.setLoggedUser({...result.data, 'name': userData.username});
      this.router.navigate(['admin']);  
    } else {
      this.snackBar.open(result.message, '', { duration: 8000, panelClass: ["custom-color-snack-error"], });
    }

    // if (userData.username === this.#validUsername && userData.password === this.#validPassword) {
    //   this.#isAuthenticated = true;
    //   localStorage.setItem('$takao.userData', JSON.stringify(userData));
    //   this.router.navigate(['admin']);
    // }
  }

  setLoggedUser(result) {
    localStorage.setItem('loggedUser', JSON.stringify(result));
    this.loggedUser = result
  }

  getLoggedUser() {
    return this.loggedUser
  }

  autoSignin() {
    let loggedUser = localStorage.getItem('loggedUser');
    // console.log(loggedUser)
    if (loggedUser) {
      this.setLoggedUser(JSON.parse(loggedUser))
      this.#isAuthenticated = true;
    } 

    // try {
    //   const parsed = JSON.parse(userData);
    //   if(parsed == null){
    //     this.signout();
    //   }
    //   if (parsed.username === this.#validUsername && parsed.password === this.#validPassword) {
    //     this.#isAuthenticated = true;
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
  }

  signout() {
    this.#isAuthenticated = false;
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  isAuthenticated() {
    return this.#isAuthenticated;
  }
}
