import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RegistrationData } from './user-registration.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationHistoryService {
  /**
   * LocalStorageKey
   */
  key = '$takao.registrationHistory';

  /**
   * History
   */
  history = new BehaviorSubject<RegistrationData[]>([]);

  constructor() {
    this.history.next(this.getAllHistory());
  }

  add(data: any): void {
    const newValue = [data, ...this.getAllHistory()];
    localStorage.setItem(this.key, JSON.stringify(newValue));
    this.history.next(newValue);
  }

  clear(): void {
    localStorage.setItem(this.key, JSON.stringify([]));
    this.history.next([]);
  }

  getAllHistory(): RegistrationData[] {
    const items = localStorage.getItem(this.key);
    try {
      const parsedItems = JSON.parse(items);
      if (!parsedItems) return [];
      return Array.isArray(parsedItems)
        ? parsedItems
        : [parsedItems];
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}
