<div class="quiz">
  <div class="quiz__container">
    <div class="quiz__container__countdown">
      <h1>
        <strong> Venha para o grid</strong><br />
        em breve novo Quiz
      </h1>
      <div *ngIf="displaySpinner$ | async; else spinner">
        <span
          ><timer-count-down class="white-card primary timer-count-down"
            [startDate]="startDate"
            [idEvent]="idEvento"
            (onTimeEnd)="startDate = null"
          ></timer-count-down
        ></span>
      </div>
      <ng-template #spinner>
        <div>
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>

      <img src="assets/images/logo_takao.png" alt="" />
    </div>
    <div class="quiz__container__award">
      <span>
        Próximo <br />
        prêmio:
      </span>
      <div>
        <img
          [src]="(pictureAward$ | async).result.proximoPremio"
          alt="premio"
        />
      </div>
      <img class="bg_circle" src="assets/images/circulo_composto.png" alt="" />
    </div>
  </div>
</div>
