import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { format, parseISO } from 'date-fns';
import { Pedido } from 'src/app/modules/models/pedido_model';
import { BrindesService } from 'src/app/services/brindes.service';
import { EventService } from 'src/app/services/event.service';
import { UserRegistrationService } from 'src/app/services/user-registration.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
@Component({
  selector: 'app-brindes-list',
  templateUrl: './brindes-list.component.html',
  styleUrls: ['./brindes-list.component.scss'],
})
export class BrindesListComponent implements OnInit {
  displayedColumns: string[] = [
    'codigoAplicador',
    'nome',
    'brinde',
    'quantidade',
    'dataSolicitacao',
    'codigoRastreio',
    'status',
  ];
  loaded: boolean = false;
  todosPedidos: any;
  pedidos: Pedido[] = [];

  constructor(
    public readonly brindesService: BrindesService,
    public readonly eventService: EventService,
    private readonly dialog: MatDialog,
    private readonly userRegistration: UserRegistrationService
  ) {}

  formatDate(date: string) {
    return format(parseISO(date.replace('Z', '')), 'dd/MM/yyyy');
  }
  ngOnInit() {
    this.todosPedidos = [];

    this.brindesService.getListTodosPedidos().then((response) => {
      this.todosPedidos = response;
      this.pedidos = this.todosPedidos.map((item: any) => {
        return {
          codigoAplicador: item.codAplicador,
          nome: item.nome,
          brinde: item.premioList[0]?.nome || '',
          quantidade: item.premioList[0]?.quantidade || 0,
          dataSolicitacao: this.formatDate(
            item.premioList[0]?.dataCadastro || ''
          ),
          codigoRastreio: item.codRastreio,
          status: item.status,
        } as Pedido;
      });
      this.loaded = true;
    });
  }
}
