import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { BrindesService } from 'src/app/services/brindes.service';

@Component({
  selector: 'app-brindes-edit',
  templateUrl: './brindes-edit.component.html',
  styleUrls: ['./brindes-edit.component.scss'],
})
export class BrindesEditComponent implements OnInit {
  numeroInserido: number;
  brindesList: number[] = [];

  @ViewChild('formDirective') private formDirective: NgForm;

  decodedJson: any;
  registering = false;
  brinde: any;
  idBrinde: any;
  imageBrind: FormData = new FormData();
  form = new FormGroup(
    {
      _id: new FormControl(''),
      nome: new FormControl(''),
      descricao: new FormControl(''),
      takoletasResgate: new FormControl(''),
      ordem: new FormControl(''),
      imageLink: new FormControl(''),
      ativo: new FormControl(1),
    },
    {}
  );
  imageBase64: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private service: BrindesService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAll();

    this.idBrinde = this.activeRoute.snapshot.params['idBrinde'];
    if (this.idBrinde != undefined) {
      this.service.getById(this.idBrinde).then((result) => {
        this.brinde = result.result;
        if (this.brinde != undefined) {
          this.form.controls._id.setValue(this.brinde._Id);
          this.form.controls.nome.setValue(this.brinde.nome);
          this.form.controls.descricao.setValue(this.brinde.descricao);
          this.form.controls.takoletasResgate.setValue(
            this.brinde.takoletasResgate
          );
          this.form.controls.imageLink.setValue(this.brinde.imageLink);
          this.form.controls.ativo.setValue(this.brinde.ativo);
          this.form.controls.ordem.setValue(this.brinde.ordem);
        } else {
          this.form.controls._id.setValue('');
          this.form.controls.nome.setValue('');
          this.form.controls.descricao.setValue('');
          this.form.controls.takoletasResgate.setValue('');
          this.form.controls.imageLink.setValue('');
          this.form.controls.ativo.setValue('');
          this.form.controls.ordem.setValue('');
        }
      });
    }
  }

  getAll() {
    this.service
      .getAll()
      .then((brindes) => {
        this.brindesList = brindes.result.map((item) => item.ordem);
      })
      .catch((error) => {
        console.error('Erro ao obter brindes:', error);
      });
  }

  async onSubmit() {
    let noImage = true;
    if (this.imageBase64) {
      this.form.controls.imageLink.setValue(this.to64(this.imageBase64));
      noImage = false;
    }
    if (this.form.valid) {
      let formData = this.form.value;
      if (this.idBrinde !== undefined) {
        try {
          if (noImage) formData = { ...formData, imageLink: '' };
          const result = await this.service.updateOne(formData);
          this.router.navigateByUrl('/admin/app-brindes');
        } catch (error) {}
      } else {
        try {
          const result = await this.service.insertOne(formData);
          this.router.navigateByUrl('/admin/app-brindes');
        } catch (error) {}
      }
    } else {
      if (this.form.value.imageLink == '') {
        this.snackBar.open('Preencha a imagem do brinde', '', {
          duration: 2000,
        });
      } else {
        this.snackBar.open('Preencha todos os campos', '', { duration: 2000 });
      }
    }
  }

  uploadImage() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.imageBase64 = reader.result as string;
        };
        reader.readAsDataURL(file);

        const imageUrl = URL.createObjectURL(file);
        const safeImageUrl: SafeUrl =
          this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }
    };
    input.click();
  }

  resetForm() {
    this.form.reset();
    this.formDirective.resetForm();
    this.form.controls.possuiQuiz.setValue(1);
    this.form.controls.status.setValue(1);
  }

  to64(stringBase: string) {
    return stringBase.replace(/^data:.+;base64,/, '');
  }
}
