<admin-page-wrapper>
  <div class="table__empty-state" style="display: flex; align-items: center; justify-content: center;" *ngIf="pedidos.length === 0 && loaded">
    <h4>Não há registro aqui 🙁</h4>
  </div>


  <div class="table__container" *ngIf="pedidos.length > 0">
    <table style="width: 100%" mat-table [dataSource]="pedidos">
      <ng-container matColumnDef="codigoAplicador">
        <th mat-header-cell *matHeaderCellDef>Código Aplicador</th>
        <td mat-cell class="codigoAplicadorstyle" *matCellDef="let pedido">{{ pedido.codigoAplicador }}</td>
      </ng-container>

      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell class="codigoNomestyle" *matCellDef="let pedido" >{{ pedido.nome }}</td>
      </ng-container>

      <ng-container matColumnDef="brinde">
        <th mat-header-cell *matHeaderCellDef>Brinde</th>
        <td mat-cell class="codigoBrindestyle" *matCellDef="let pedido">{{ pedido.brinde }}</td>
      </ng-container>

      <ng-container matColumnDef="quantidade">
        <th mat-header-cell *matHeaderCellDef>Quantidade</th>
        <td mat-cell *matCellDef="let pedido">{{ pedido.quantidade }}</td>
      </ng-container>

      <ng-container matColumnDef="dataSolicitacao">
        <th mat-header-cell *matHeaderCellDef>Data da Solicitação</th>
        <td mat-cell *matCellDef="let pedido">{{ pedido.dataSolicitacao }}</td>
      </ng-container>

      <ng-container matColumnDef="codigoRastreio">
        <th mat-header-cell *matHeaderCellDef>Código Rastreio</th>
        <td mat-cell *matCellDef="let pedido">{{ pedido.codigoRastreio }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let pedido">{{ pedido.status }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


</admin-page-wrapper>