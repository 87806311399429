<div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="header">
    <button mat-icon-button (click)="sidenav.toggle();">
      <!-- <mat-icon>menu</mat-icon> -->
      <span class="material-icons">menu</span>
    </button>  
    <span class="title">{{title}}</span>
    <span class="spacer"></span>  
</div>

<div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="sub-header"> 
</div>