import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { QuizService } from 'src/app/services/quiz.service';
import { FirebaseQuizz } from '../remote-control/remote-control.component';
import { parseISO, isAfter } from 'date-fns';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
  constructor(
    public quizService: QuizService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly firestore: AngularFirestore
  ) { }
  _sub: Subscription;

  isBooted = false;
  displaySpinner$ = new BehaviorSubject<boolean>(false);
  startDate = null;
  teste = new BehaviorSubject<any>('');
  eventData = <any>{};
  
  async ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        let selectedEvent = params.event;

        if (!selectedEvent) {
          this.router.navigateByUrl('admin/events');
        } else {
          this.quizService.SetSelectedService(selectedEvent)
        }

        this.quizService.subscribe(selectedEvent);

        this._sub = this.firestore
          .collection('feiras')
          .doc<FirebaseQuizz>(selectedEvent)
          .valueChanges()
          .subscribe(async (data) => {
            this.isBooted = data.iniciado === true;
            const {
              ir_para_tela,
              horario_do_quiz,
              iniciado,
              exibir_contador,
              token_evento: token,
              id_evento: idEvento,
              codigo_evento: codigoEvento,
              nome_evento: nome,
              previsao_fim: previsaoFim,
            } = data;

            this.eventData = {
              idEvento,
              codigoEvento,
              previsaoFim,
              nome
            }

            this.displaySpinner$.next(exibir_contador);
            this.router.navigateByUrl(ir_para_tela+'?event='+ selectedEvent);
            const startDate = parseISO(horario_do_quiz);
            
            this.startDate = isAfter(startDate, new Date()) ? startDate : null;
            this.router.navigateByUrl(data.ir_para_tela+'?event='+ selectedEvent);
          });
      }
      );

    
  }
}
