// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://app-takao-beta-windows.azurewebsites.net/v1',
  collection: 'feiras',
  firebase: {
    apiKey: 'AIzaSyDZC9GO1mexu3ChOrnIneXcv5qwv250wys',
    authDomain: 'push-notification-app-3ce9c.firebaseapp.com',
    databaseURL:
      'https://push-notification-app-3ce9c-default-rtdb.firebaseio.com',
    projectId: 'push-notification-app-3ce9c',
    storageBucket: 'push-notification-app-3ce9c.appspot.com',
    messagingSenderId: '401826130740',
    appId: '1:401826130740:web:ffcd8918a9ab6ce802d361',
    measurementId: 'G-7PE4J0N3NZ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
