import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { format, parseISO } from 'date-fns';

import { RegistrationHistoryService } from 'src/app/services/registration-history.service';
import { UserRegistrationService } from 'src/app/services/user-registration.service';
import { QrcodeDialogComponent } from '../admin/qrcode-dialog/qrcode-dialog.component';
import { EventService } from 'src/app/services/event.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent {
  displayedColumns: string[] = [
    'name',
    'codigoEvento',
    'startEvent',
    'endEvent',
    'status',
    'actions',
  ];

  events: any;
  loaded: boolean = false;

  constructor(
    public readonly eventService: EventService,
    private readonly dialog: MatDialog,
    private readonly userRegistration: UserRegistrationService
  ) {}

  formatDate(date: string) {
    return format(parseISO(date.replace('Z', '')), "dd/MM/yyyy 'às' HH:mm");
  }
  ngOnInit() {
    this.events = [];

    this.eventService.listAll().then((response) => {
      this.events = response.sort((a, b) => {
        let fa = a.nome.toLowerCase(),
          fb = b.nome.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      this.loaded = true;
    });
  }
}
