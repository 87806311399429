<div class="container">
  <div class="half">
    <div class="formulario">
      <h2>Preencha o Formulário:</h2>
      <div class="admin-registration-form__form-field-group">
        <div class="image-container">
          <img *ngIf="imageBase64 !== '' || imageBase64 !== null" [src]="imageBase64" alt="Imagem selecionada">
          <img *ngIf="imageBase64 === '' || imageBase64 === null" [src]="form.get('image').value" alt="Selecione sua Imagem">
          <button type="button" (click)="uploadImage()" class="btn-upload">Escolher Arquivo</button>
        </div>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="campo1">Titulo:</label> 
          <input type="text" class="form-control" id="campo1" formControlName="titulo" [(ngModel)]="titulo">
          <div *ngIf="form.controls.titulo.invalid && (form.controls.titulo.dirty || form.controls.titulo.touched)" class="error">
            Titulo é obrigatório.
          </div>
        </div>
        <div class="form-group">
          <label for="campo2">Mensagem:</label>
          <input type="text" class="form-control" id="campo2" formControlName="mensagem" [(ngModel)]="mensagem">
          <div *ngIf="form.controls.mensagem.invalid && (form.controls.mensagem.dirty || form.controls.mensagem.touched)" class="error">
            Mensagem é obrigatória.
          </div>
        </div>
        <div class="form-group">
          <label for="campo3">FeatFlag:</label>
          <input type="text" class="form-control" id="campo3" formControlName="featFlag">
        </div>
        <div class="form-group">
          <label for="campo4">Rota no App:</label>
          <input type="text" class="form-control" id="campo4" formControlName="rotaApp">
        </div>
        <div class="form-group">
          <label for="filtro">Tipo de Perfil:</label>
          <select id="filtro" style="width: 100%; padding: 8px; border-radius: 5px; border: 2px solid #ccc;" formControlName="tipoPerfil">
            <option value="0">Todos</option>
            <option value="1">Administrador</option>
            <option value="3">Mecânico(a)</option>
            <option value="4">Autopeça</option>
            <option value="5">Retifica</option>
            <option value="99">Outros(as)</option>
          </select>
        </div>
        <div class="form-group">
          <label for="campo5">Takoletas:</label>
          <input type="number" class="form-control" id="campo6" step="1" formControlName="takoletas">
        </div>
        <div class="form-group">
          <label for="campo6">Data e Hora de Envio</label>
          <input type="datetime-local" class="form-control" id="campo7" formControlName="dataHora">
        </div>
        <button type="submit" class="btn btn-primary" style="margin-bottom: 50px; width: 100%; font-size: large;">Enviar</button>
      </form>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="mobile">
    <div class="phone">
       <div class="phone-mirror">
           <div class="topWrapper">
               <div class="camera"></div>
               <div class="line-rec"></div>
           </div>
           <div class="notification">
            <div class="icon">
              <mat-icon class="icon">circle_notifications</mat-icon>
            </div>            
            <div class="push">
              <div class="push-title">{{titulo.trim() === '' ? "Default message" : (titulo.length > 15 ? titulo.slice(0, 15) + '...' : titulo)}}</div>
              <div class="push-description">{{mensagem.trim() === '' ? "Default message" : (mensagem.length > 22 ? mensagem.slice(0, 22) + '...' : mensagem)}}</div>
             </div>
           </div>
            <img src="/assets/images/screen_cel.jpg" alt="oppo" height="535" width="312">
          </div>
    </div>
  </div>
</div>
