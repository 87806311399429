import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseQuizz } from '../remote-control/remote-control.component';
import { QuizService } from 'src/app/services/quiz.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-podium',
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss'],
})
export class PodiumComponent implements OnInit, OnDestroy {
  winners$ = new BehaviorSubject<Array<any>>([]);
  isLoadingThumbs$ = new BehaviorSubject<boolean>(true);
  _sub: Subscription | undefined;
  idEvento: string = "";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private quizService: QuizService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnDestroy() {
    if (this._sub) this._sub.unsubscribe();
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(value => this.idEvento = value.event);
    this.route.queryParams
      .subscribe(params => {
        let selectedEvent = params.event;

        if (!selectedEvent) {
          this.router.navigateByUrl('admin/events');
        } else {
          this.quizService.SetSelectedService(selectedEvent)
        }

        this._sub = this.firestore
          .collection('feiras')
          .doc<FirebaseQuizz>(selectedEvent)
          .valueChanges()
          .subscribe(async (data) => {
            this.quizService.fetchWinners(data.token_evento, this.idEvento).then((response) => {
              this.isLoadingThumbs$.next(false);
              this.winners$.next(response);
            });
            this.router.navigateByUrl(data.ir_para_tela + '?event=' + selectedEvent);
          });
      }
      );
  }
}
