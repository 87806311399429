import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { differenceInSeconds } from 'date-fns';
import { QuizService } from 'src/app/services/quiz.service';

@Component({
  selector: 'timer-count-down',
  templateUrl: './timer-count-down.component.html',
  styleUrls: ['./timer-count-down.component.scss'],
})
export class TimerCountDownComponent implements OnInit, OnDestroy {
  @Input()
  public startDate: Date;
  public idEvent: string;

  @Output()
  onTimeEnd = new EventEmitter();

  seconds = 0;
  _timeoutId: number | null;

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly quizService: QuizService) {}

  ngOnChanges() {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  ngOnInit(): void {
    console.log(this.startDate);
    this.startTimer();
  }

  get clock(): string {
    const seconds = this.seconds % 60;
    const minutes = Math.floor((this.seconds % 3600) / 60);
    const hours = Math.floor(this.seconds / 3600);
    let time = [hours, minutes, seconds]
      .filter((n, i) => {
        if (i < 2) return !!n;
        return true;
      })
      .map((n) => String(n).padStart(2, '0'))
      .join(':');
      if(time.length < 3) {
        return `00:${time}`
      }
      return time;
  }

  private startTimer() {
    if (!this.startDate || !(this.startDate instanceof Date)) return;

    // clear old timeout
    if (!!this._timeoutId) clearInterval(this._timeoutId);

    const totalSeconds = this.parseTimeToSeconds(this.startDate);

    // zero based seconds.
    if (!totalSeconds) return;

    // update seconds state.
    this.seconds = totalSeconds;

    const handler = () => {
      this.seconds--;
      if (this.seconds <= 0) {
        this.onTimeEnd.emit();
        this.firestore.collection('feiras').doc(this.idEvent).update({
          exibir_contador: false,
        });
        clearInterval(this._timeoutId);
      }
    };

    this._timeoutId = setInterval(handler, 1000) as any;
  }

  private parseTimeToSeconds(ref: Date): number {
    const sec = differenceInSeconds(ref, new Date());
    return sec >= 0 ? sec : 0;
  }

  private stopTimer() {
    if (!!this._timeoutId) clearInterval(this._timeoutId);
    this._timeoutId = null;
    this.seconds = 0;
  }
}
