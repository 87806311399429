import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatFlagService {
  constructor(private readonly http: HttpClient) {}

  async getAllFlags(): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/PainelApp/ListarTodasFeatFlags';
      const response = await this.http.get(url).toPromise<any>();

      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async insertOneFeatFlag(featFlag): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/PainelApp/CriarFeatFlag';
      const response = await this.http.post(url, featFlag).toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async deleteById(idFeatFlag, nome) {
    const url = environment.apiBaseUrl + `/PainelApp/DeletarFeatFlag`;
    const requestDeletarFeatFlag = {
      idFeatFlag: idFeatFlag,
      nome: nome,
    };

    try {
      const response = await this.http
        .post(url, requestDeletarFeatFlag)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error('Erro ao excluir o FeatFlag:', error);
      throw error;
    }
  }

  async getFlagsUser(idUsuario): Promise<any> {
    try {
      const url =
        environment.apiBaseUrl +
        `/PainelApp/ListarFeatFlagsUsuario?idUsuario=${idUsuario}`;
      const response = await this.http.get(url).toPromise<any>();

      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getPendingFlags(idUsuario): Promise<any> {
    try {
      const url =
        environment.apiBaseUrl +
        `/PainelApp/GetFeatFlagPendente?idUsuario=${idUsuario}`;
      const response = await this.http.get(url).toPromise<any>();

      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async AdicionarFeatFlagUser(idUsuario, idFeatFlag): Promise<any> {
    try {
      const url =
        environment.apiBaseUrl + '/PainelApp/AdicionarFeatFlagUsuario';
      const requestAddFeatFlagUser = {
        idUsuario: idUsuario,
        idFeatFlag: idFeatFlag,
      };

      const response = await this.http
        .post(url, requestAddFeatFlagUser)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }
  async AdicionarFeatFlagsEmMassa(nomeFeatFlag,idFeatFlag,FeatFlagAdd): Promise<any> {
    try {

      const url = environment.apiBaseUrl + `/PainelApp/LiberacaoEmMassaBaseadaEmFeatFlag`;

      const AddFeatFlagEmMassa = {
        nomeFeatFlag: nomeFeatFlag,
        idFeatFlag: idFeatFlag,
        FeatFlagAdd:FeatFlagAdd,
      };

      const response = await this.http
        .post(url, AddFeatFlagEmMassa)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      return error;
    }
  }

  async removerFeatFlagUser(idUsuario, idFeatFlag) {
    const url = environment.apiBaseUrl + `/PainelApp/RemoverFeatFlagUsuario`;
    const requestRemoverFeatFlagUser = {
      idUsuario: idUsuario,
      idFeatFlag: idFeatFlag,
    };

    try {
      const response = await this.http
        .post(url, requestRemoverFeatFlagUser)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error('Erro ao remover a FeatFlag do usuário:', error);
      throw error;
    }
  }

  getUserCodAplicador(codAplicador) {
    const url =
      environment.apiBaseUrl +
      `/ClienteAplicador/GetCodAplicador?codAplicador=${codAplicador}`;

    return this.http.get<any[]>(url);
  }
}
