import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteConfirmComponent } from 'src/app/components/delete-confirm/delete-confirm/delete-confirm.component';
import { FlagsPendentesComponent } from 'src/app/components/flags-pendentes/flags-pendentes.component';
import { FeatFlagService } from 'src/app/services/feat-flag.service';

@Component({
  selector: 'app-adicionar-feat-flag-user',
  templateUrl: './adicionar-feat-flag-user.component.html',
  styleUrls: ['./adicionar-feat-flag-user.component.scss'],
})
export class AdicionarFeatFlagUserComponent implements OnInit {
  @ViewChild('modal') modal: TemplateRef<any>;

  loaded: boolean = true;
  userAplicador: any = null;
  valor: any;
  result: any;
  searchQuery: any;
  flagsUser: [];
  flagsUserPend: [];
  data: any;
  dialogRef: any;
  listaDeFeatFlag: [];
  filteredFeatFlags = [];
  selectedFeatFlagBase: any;
  selectedFeatFlagToAdd: any;
  isButtonDisabled = true;
  idFeatFlagBase: String;


  constructor(
    private service: FeatFlagService,
    private router: Router,
    private dialog: MatDialog
  ) { }
  async ngOnInit() {
    await this.getListarTodasFlags();
    this.filteredFeatFlags = this.listaDeFeatFlag.slice();
  }

  onBaseSelectionChange() {
    this.idFeatFlagBase = this.selectedFeatFlagBase?._id;
    this.checkButtonState();
  }

  onAddSelectionChange() {
    this.checkButtonState();
  }

  checkButtonState() {
    if (!this.selectedFeatFlagBase || !this.selectedFeatFlagToAdd || this.selectedFeatFlagBase === this.selectedFeatFlagToAdd) {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  open() {
    this.getPendingFlagsUser(this.userAplicador._Id).then(() => {
      const dialogRef = this.dialog.open(this.modal, {
        width: '600px',
      });
    });
  }
  fecharModal() {
    const dialogRef = this.dialog.closeAll();
  }


  async aplicar(nomeFeatFlag, idFeatFlag, FeatFlagAdd) {
    try {

      const result = await this.service.AdicionarFeatFlagsEmMassa(
        nomeFeatFlag['nome'],
        idFeatFlag['_Id'],
        FeatFlagAdd['nome'],
      );
      this.selectedFeatFlagBase = null;
      this.idFeatFlagBase = null;
      this.selectedFeatFlagToAdd = null;
    } catch (error) {
    }
  }
  async onSubmit(idUsuario, idFeatFlag) {
    try {
      const result = await this.service.AdicionarFeatFlagUser(
        idUsuario,
        idFeatFlag
      );
      this.getPendingFlagsUser(this.userAplicador._Id);
      await this.service.getUserCodAplicador(this.searchQuery).subscribe(
        async (data) => {
          this.result = data;
          this.userAplicador = this.result.result;

          if (this.userAplicador) {
            await this.getAllFlags(this.userAplicador._Id);
          }
        },
        (error) => {
          console.error('Erro ao obter a lista de pedidos:', error);
        }
      );
    } catch (error) { }
  }

  async buscar() {
    this.flagsUser = [];
    const user = await this.service
      .getUserCodAplicador(this.searchQuery)
      .subscribe(
        async (data) => {
          this.result = data;
          this.userAplicador = this.result.result;

          if (this.userAplicador) {
            await this.getAllFlags(this.userAplicador._Id);
          }
        },
        (error) => {
          console.error('Erro ao obter a lista de pedidos:', error);
        }
      );
  }

  async getAllFlags(idUsuario) {
    try {
      const flagsResponse = await this.service.getFlagsUser(idUsuario);

      this.flagsUser = flagsResponse || [];
    } catch (error) {
      console.error('Erro ao obter featFlags:', error);
    }
  }
  async getListarTodasFlags() {
    try {
      const flagsResponse = await this.service.getAllFlags();

      this.listaDeFeatFlag = flagsResponse || [];
    } catch (error) {
      console.error('Erro ao obter featFlags:', error);
    }
  }

  async getPendingFlagsUser(idUsuario) {
    try {
      const flagsResponse = await this.service.getPendingFlags(idUsuario);

      this.flagsUserPend = flagsResponse || [];
    } catch (error) {
      console.error('Erro ao obter featFlags:', error);
    }
  }

  openDeleteConfirmationModal(idUsuario, idFeatFlag): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '350px',
      data: { elementData: idUsuario && idFeatFlag },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.service.removerFeatFlagUser(idUsuario, idFeatFlag);
          await this.service.getUserCodAplicador(this.searchQuery).subscribe(
            async (data) => {
              this.result = data;
              this.userAplicador = this.result.result;

              if (this.userAplicador) {
                await this.getAllFlags(this.userAplicador._Id);
              }
            },
            (error) => {
              console.error('Erro ao obter a lista de pedidos:', error);
            }
          );
        } catch (error) { }
      }
    });
  }
}
