import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrindesService {
  constructor(private readonly http: HttpClient) {}

  getAll() {
    const url = environment.apiBaseUrl + '/Premio/Listar';
    return this.http.get(url).toPromise<any>();
  }

  getById(id) {
    const url = environment.apiBaseUrl + `/Premio/GetByIdPremio?id=${id}`;
    return this.http.get(url).toPromise<any>();
  }

  async insertOne(brinde): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/Premio/Criar';
      const response = await this.http.post(url, brinde).toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async deleteById(idPremio, nome) {
    const url = environment.apiBaseUrl + `/Premio/DeletarPremio`;
    const requestDeletarPremio = {
      idPremio: idPremio,
      nome: nome,
    };
    try {
      const response = await this.http
        .post(url, requestDeletarPremio)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error('Erro ao excluir o prêmio:', error);
      throw error;
    }
  }

  async updateOne(brinde): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/Premio/Atualizar';

      const response = await this.http.post(url, brinde).toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  getListPedidos() {
    const url = environment.apiBaseUrl + '/Premio/ListarPedidos';
    return this.http.get<any[]>(url);
  }

  async getListTodosPedidos(): Promise<any> {
    try {
      const { apiBaseUrl } = environment;
      const url = `${apiBaseUrl}/Premio/ListarTodosPedidos`;
      const response = await this.http.get(url).toPromise<any>();
      return response?.result || [];
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  alterarStatus(pedido) {
    const url = environment.apiBaseUrl + '/Premio/AlterarStatusPedido';
    return this.http.post<any[]>(url, pedido);
  }

  sendCodRastreio(pedido) {
    const url = environment.apiBaseUrl + '/Premio/SetCodRastreio';
    return this.http.post<any[]>(url, pedido);
  }
}
