<mat-sidenav-container class="sidebar-container">
  <!-- <mat-sidenav mode="side" [opened]="true">
    <div class="sidebar-wrapper">
      <button mat-icon-button class="sidebar--wrapper__menu-tem" (click)="navigate('/admin')">
        <mat-icon>group_add</mat-icon>
      </button>
      <button mat-icon-button class="sidebar--wrapper__menu-tem" (click)="navigate('/admin/history')">
        <mat-icon>history</mat-icon>
      </button>
      <button mat-icon-button class="sidebar--wrapper__menu-tem" (click)="navigate('/')">
        <mat-icon>slideshow</mat-icon>
      </button>
      <button mat-icon-button class="sidebar--wrapper__menu-tem" (click)="navigate('/admin/remote-control')">
        <mat-icon>settings_remote</mat-icon>
      </button>
      <button mat-icon-button class="sidebar--wrapper__menu-tem" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-sidenav> -->

  <mat-sidenav-content>
    <div class="admin-page-wrapper__container">
      <mat-card>
        <mat-card-content>
          <ng-content></ng-content>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
