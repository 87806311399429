<div class="loading-backdrop" *ngIf="isLoading">
  <div class="loadingio-spinner-rolling-6xgi3puknxi">
    <div class="ldio-ifpg4ad0ovc">
      <div></div>
    </div>
  </div>
</div>
<style type="text/css">
  .loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  @keyframes ldio-ifpg4ad0ovc {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-ifpg4ad0ovc div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #e15b64;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-ifpg4ad0ovc div {
    animation: ldio-ifpg4ad0ovc 1s linear infinite;
    top: 100px;
    left: 100px
  }
  .loadingio-spinner-rolling-6xgi3puknxi {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio-ifpg4ad0ovc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-ifpg4ad0ovc div { box-sizing: content-box; }
  /* generated by https://loading.io/ */
  </style>

<admin-page-wrapper>

 

  <div class="filtro-container">
    <label for="filtro">Filtrar por:</label>
    <select id="filtro" [(ngModel)]="filtro" (change)="onFiltroChange($event)">
      <option value="0">Todos</option>
      <option value="1">Nome do Registro</option>
      <option value="2">Tipo do Registro</option>
      <option value="3">Informações</option>
      <option value="4">Data do Registro</option>
      <option value="5">Nome do Usuario</option>
    </select>

    <ng-container *ngIf="filtro !== '0'">
      <label *ngIf="filtro === '1'" for="valor">Filtrar por Nome do Registro:</label>
      <select *ngIf="filtro === '1'" id="filtroNomeRegistro" [(ngModel)]="valor">
        <option *ngFor="let item of tiposDeFiltro" [value]="item">{{ item }}</option>
      </select>

      <label *ngIf="filtro === '2'" for="valor">Filtrar por Tipo de Registro:</label>
      <select *ngIf="filtro === '2'" id="filtroTipoDeRegistro" [(ngModel)]="valor">
        <option *ngFor="let item of tiposDeFiltro" [value]="item">{{ item }}</option>
      </select>

      <label *ngIf="filtro === '3'" for="valor">Filtrar por informações:</label>
      <input *ngIf="filtro === '3'" id="filtroInformacoes" type="text" [(ngModel)]="valor"
        placeholder="Digite as informações">

      <label *ngIf="filtro === '4'" for="valorData">Filtrar por Data do Registro:</label>
      <input *ngIf="filtro === '4'" id="filtroDataRegistro" type="date" [(ngModel)]="valorData">

      <label *ngIf="filtro === '5'" for="valor">Filtrar por Nome do Usuário:</label>
      <select *ngIf="filtro === '5'" id="filtroNomeUsuario" [(ngModel)]="valor">
        <option *ngFor="let item of tiposDeFiltro" [value]="item">{{ item }}</option>
      </select>
    </ng-container>

    <button (click)="applyFilter()">Aplicar Filtro</button>
  </div>


  <div class="table__empty-state" style="display: flex; align-items: center; justify-content: center;"
    *ngIf="registros.length === 0 && loaded">
    <h4>Não há registro aqui 🙁</h4>
  </div>

  <div class="table__container" *ngIf="registros.length > 0">
    <table style="width: 100%" mat-table [dataSource]="registros">
      <ng-container matColumnDef="nomeUsuario">
        <th mat-header-cell *matHeaderCellDef>Nome do usuário</th>
        <td mat-cell class="codigoAplicadorstyle" *matCellDef="let registros">{{ registros.nomeUsuario }}</td>
      </ng-container>

      <ng-container matColumnDef="nomeRegistro">
        <th mat-header-cell *matHeaderCellDef>Nome do registro</th>
        <td mat-cell class="codigoNomestyle" *matCellDef="let registros">{{ registros.nomeRegistro }}</td>
      </ng-container>

      <ng-container matColumnDef="mensagemAcao">
        <th mat-header-cell *matHeaderCellDef>Infos</th>
        <td mat-cell class="codigoBrindestyle" *matCellDef="let registros">{{ registros.mensagemAcao }}</td>
      </ng-container>

      <ng-container matColumnDef="tipoRegistro">
        <th mat-header-cell *matHeaderCellDef>Tipo de registro</th>
        <td mat-cell *matCellDef="let registros">{{ registros.tipoRegistro }}</td>
      </ng-container>

      <ng-container matColumnDef="dataRegistro">
        <th mat-header-cell *matHeaderCellDef>Data do registro</th>
        <td mat-cell *matCellDef="let registros">{{ formatDate(registros.dataRegistro) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


</admin-page-wrapper>