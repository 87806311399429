import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AreaAtuacaoService {
  allOptions = [
    { value: 3, label: 'Mecânico(a)' },
    { value: 4, label: 'Autopeça' },
    { value: 5, label: 'Retifica' },
    { value: 99, label: 'Outros(as)' },
  ]
  constructor() { }
}
