import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationData } from './user-registration.service';

@Pipe({
  name: 'historyfilter'
})
export class RegistrationHistoryPipe implements PipeTransform {

  transform(value: RegistrationData[], search: string): unknown {
    if (!value || !search)
      return value;
    const _search = search.toLowerCase();
    return value.filter(item => {
      return item.nome.toLowerCase().includes(_search) || item.cpf.includes(_search);
    })
  }

}
