import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { parseISO, isAfter } from 'date-fns';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FirebaseQuizz } from '../remote-control/remote-control.component';
import { QuizService } from 'src/app/services/quiz.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  pictureAward$: Observable<string>;
  constructor(
    private readonly firestore: AngularFirestore,
    private router: Router,
    private quizService: QuizService,
    private activeRoute: ActivatedRoute
  ) {
    this.pictureAward$ = this.quizService.fetchNextAward();
  }
  _sub: Subscription;
  /**
   *
   */
  isBooted = false;
  startDate = null;
  displaySpinner$ = new BehaviorSubject<boolean>(false);
  idEvento;

  async ngOnInit() {
    this.activeRoute.queryParams.subscribe(
      (value) => (this.idEvento = value.event)
    );
    this._sub = this.firestore
      .collection('feiras')
      .doc<FirebaseQuizz>(this.idEvento)
      .valueChanges()
      .subscribe(async (data) => {
        this.router.navigateByUrl(data.ir_para_tela);
        this.isBooted = data.iniciado === true;
        const { horario_do_quiz, exibir_contador } = data;

        const startDate = parseISO(horario_do_quiz);
        this.displaySpinner$.next(exibir_contador);
        // start count down
        this.startDate = isAfter(startDate, new Date()) ? startDate : null;
      });
  }
}
