import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RegistrationHistoryService } from './registration-history.service';

export interface RegistrationData {
  cpf: string;
  cnpj: string;
  nomeMecanica: string;
  nome: string;
  senha: string;
  perfil: string;
  telefone: string;
  endereco: {
    logradouro: string;
    numero: string;
    cep: string;
    bairro: string;
    complemento?: string;
  };
  dataNascimento: string;
}

interface ViacepResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface RequestResult<T> {
  success: boolean;
  data?: T | null;
}

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {
  constructor(private readonly http: HttpClient, private readonly history: RegistrationHistoryService) {}

  async signup(data: RegistrationData): Promise<RequestResult<void>> {
    const url = environment.apiBaseUrl + '/ClienteAplicador/Create';
    try {
      const postData = {
        cpf: data.cpf,
        nome: data.nome,
        senha: data.senha,
        senhaConfirmar: data.senha,
        telefone: data.telefone,
        cnpj: data.cnpj,
        nomeOficina : data.nomeMecanica,
        perfil: String(data.perfil),
        endereco: {
          ...data.endereco,
          numero: String(data.endereco.numero),
          cep: data.endereco.cep.replace(/[^\d]+/, ''),
        },
        DataNascimento: data.dataNascimento.split('/').reverse().join('-'),
        IsVerificado: true,
      };
      const response = await this.http.post(url, postData).toPromise<any>();   
      if (response.success === false){       
        return { success: false, data: response?.result?.message };
      }       
      this.history.add({ ...postData, createdAt: new Date().toISOString() });
      return { success: true, data: response?.result?.message };
    } catch (err) {   
      return { success: false, data: null };
    }
  }

  async getAddressByZipcode(zipcode: string): Promise<RequestResult<ViacepResponse>> {
    const sanitizedZipcode = zipcode.replace(/[^\d]+/gi, '');
    const url = `https://viacep.com.br/ws/${sanitizedZipcode}/json/`;
    try {
      const response = await this.http.get(url).toPromise();
      return { success: true, data: response as ViacepResponse }
    } catch (err) {
      return { success: false, data: null };
    }
  }

  createQrcodeValue(data: RegistrationData): string {
    const { cpf, senha } = data;
    const parsedCpf = String(cpf).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/gi, '$1.$2.$3-$4');
    return `{"login":"${parsedCpf}","password":"${senha}"}`;
  }
}
