import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TakoletasService {
  constructor(private readonly http: HttpClient) {}

  async creditarTakoletas(codAplicador, motivo, takoletas): Promise<any> {
    try {
      const url = environment.apiBaseUrl + '/PainelApp/CreditarTakoletas';
      const requestAddTakoletas = {
        codAplicador: codAplicador,
        motivo: motivo,
        takoletas: takoletas
      };

      const response = await this.http
        .post(url, requestAddTakoletas)
        .toPromise<any>();
      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }


}
