import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteConfirmComponent } from 'src/app/components/delete-confirm/delete-confirm/delete-confirm.component';
import { BrindesService } from 'src/app/services/brindes.service';

@Component({
  selector: 'app-brindes',
  templateUrl: './brindes.component.html',
  styleUrls: ['./brindes.component.scss'],
})
export class BrindesComponent implements OnInit {
  loaded: boolean = true;
  brindes = [];

  displayedColumns: string[] = [
    'imagem',
    'name',
    'descricao',
    'quantidadeEstoque',
    'takoletas',
    'ordem',
    'ativo',
    'actions',
  ];

  constructor(
    private readonly service: BrindesService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.service
      .getAll()
      .then((brindes) => {
        this.brindes = brindes.result;
      })
      .catch((error) => {
        console.error('Erro ao obter brindes:', error);
      });
  }

  openDeleteConfirmationModal(element, nome): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '350px',
      data: { elementData: element && nome },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.service.deleteById(element, nome);
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([
                this.router.navigateByUrl('/admin/app-brindes'),
              ]);
            });
        } catch (error) {}
      }
    });
  }
}
