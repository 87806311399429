import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteConfirmComponent } from 'src/app/components/delete-confirm/delete-confirm/delete-confirm.component';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss'],
})
export class BannerListComponent implements OnInit {
  loaded: boolean = true;
  banners = [];

  displayedColumns: string[] = [
    'imagem',
    'nome',
    'dataInicio',
    'dataFim',
    'ordem',
    'ativo',
    'actions',
    'featureFlag',
  ];

  constructor(
    private service: BannerService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAll();
  }

  async getAll() {
    try {
      const bannersResponse = await this.service.getAllBanners();

      this.banners = bannersResponse || [];
    } catch (error) {
      console.error('Erro ao obter banners:', error);
    }
  }

  openDeleteConfirmationModal(element, nome): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '350px',
      data: { elementData: element && nome },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.service.deleteById(element, nome);
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([
                this.router.navigateByUrl('/admin/app-banner-list'),
              ]);
            });
        } catch (error) {}
      }
    });
  }
}
