import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PushControllerService } from 'src/app/services/push-controller.service';

@Component({
  selector: 'app-push-controller',
  templateUrl: './push-controller.component.html',
  styleUrls: ['./push-controller.component.scss'],
})
export class PushControllerComponent implements OnInit {
  titulo: string = '';
  mensagem: string = '';

  form = new FormGroup(
    {
      titulo: new FormControl('', Validators.required),
      mensagem: new FormControl('', Validators.required),
      featFlag: new FormControl(''),
      rotaApp: new FormControl(''),
      tipoPerfil: new FormControl(''),
      takoletas: new FormControl(''),
      dataHora: new FormControl(''),
      imagem: new FormControl(''),
    },
    {}
  );
  imageBase64: string;
  filtro = '0';

  constructor(
    private sanitizer: DomSanitizer,
    private service: PushControllerService
  ) {}

  ngOnInit(): void {}

  async onSubmit() {
    this.form.markAllAsTouched();
    let noImage = true;
    if (this.imageBase64) {
      this.form.controls.imagem.setValue(this.to64(this.imageBase64));
      noImage = false;
    }
    if (this.form.value.takoletas === '') {
      this.form.controls.takoletas.setValue('0');
    }
    if (this.form.valid) {
      let formData = this.form.value;
      try {
        if (noImage) formData = { ...formData, imagem: '' };
        const result = await this.service.agendarPush(formData);
      } catch (error) {
        console.error('Erro ao agendar o push:', error);
      }
    }
  }

  to64(stringBase: string) {
    return stringBase.replace(/^data:.+;base64,/, '');
  }

  uploadImage() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.imageBase64 = reader.result as string;
        };
        reader.readAsDataURL(file);

        const imageUrl = URL.createObjectURL(file);
        const safeImageUrl: SafeUrl =
          this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }
    };
    input.click();
  }
}

export default PushControllerComponent;
