import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { parseISO, isAfter } from 'date-fns';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FirebaseQuizz } from '../remote-control/remote-control.component';
import { ActivatedRoute, Router } from '@angular/router';
import { QuizService } from 'src/app/services/quiz.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
})
export class InstructionsComponent implements OnInit {
  constructor(
    private readonly firestore: AngularFirestore,
    private readonly quizService: QuizService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  _sub: Subscription;
  /**
   *
   */
  isBooted = false;
  startDate = null;
  displaySpinner$ = new BehaviorSubject<boolean>(false);
  eventData;

  async ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        let selectedEvent = params.event;

        if (!selectedEvent) {
          this.router.navigateByUrl('admin/events');
        } else {
          this.quizService.SetSelectedService(selectedEvent)
        }

    this._sub = this.firestore
      .collection('feiras')
      .doc<FirebaseQuizz>(selectedEvent)
      .valueChanges()
      .subscribe(async (data) => {
        this.isBooted = data.iniciado === true;
        // console.log(data);
        const {
          ir_para_tela,
          horario_do_quiz,
          exibir_contador,
          token_evento: token,
          id_evento: idEvento,
          iniciado,
          codigo_evento: codigoEvento,
          nome_evento: nome,
          previsao_fim: previsaoFim,
        } = data;

        this.eventData = {
          idEvento,
          codigoEvento,
          nome,
          previsaoFim
        }

        this.router.navigateByUrl(ir_para_tela + '?event=' + selectedEvent);

        this.displaySpinner$.next(exibir_contador);

        const startDate = parseISO(horario_do_quiz);

        // start count down
        this.startDate = isAfter(startDate, new Date()) ? startDate : null;
      });
    }
    );
  }
}
