<div>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #leftSidenav mode="side" opened>
        <app-left-menu></app-left-menu>
      </mat-sidenav>
      <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'">
        <app-header [sidenav]="leftSidenav"></app-header>
        <div class="main_content">
          <div style="padding: 20px">          
            <router-outlet></router-outlet>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  
  </div>