import { Component, OnInit } from '@angular/core';
import { onMainContentChange } from '../animations/animations';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [ onMainContentChange ]
})
export class MenuComponent {

  name = 'Angular';
  public onSideNavChange: boolean;

  constructor() {}

}
