<admin-page-wrapper>
    <div class="table__empty-state" style="display: flex; align-items: center; justify-content: center;" *ngIf="banners.length === 0 && loaded">
        <h4>Não há registro aqui 🙁</h4>
    </div>
    <div style="width: 100%;" class="table__container" *ngIf="banners.length > 0">
        <table style="width: 100%" mat-table [dataSource]="banners">
            <ng-container matColumnDef="imagem">
                <th mat-header-cell *matHeaderCellDef><strong>Imagem</strong></th>
                <td mat-cell *matCellDef="let element">
                    <img style="width: 96px; height: 60px;" [src]="element.imageLink" alt="Imagem do banner">
                </td>
            </ng-container>
            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef><strong>Nome do Banner</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.nome}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="dataInicio">
                <th mat-header-cell *matHeaderCellDef><strong>Data de Início</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.dataInicio}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="dataFim">
                <th mat-header-cell *matHeaderCellDef><strong>Data de Término</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.dataFim}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="ordem">
                <th mat-header-cell *matHeaderCellDef><strong>Ordem</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.ordem}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="featureFlag">
                <th mat-header-cell *matHeaderCellDef><strong>Feature Flag</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.featureFlag}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="ativo">
                <th mat-header-cell *matHeaderCellDef><strong>Ativo</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.ativo ? 'Ativo' : 'Inativo'}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                            [routerLink]="['/admin/app-banner-edit/', element._Id]"><mat-icon>edit</mat-icon>Editar
                        </button>
                        <button mat-menu-item (click)="openDeleteConfirmationModal(element._Id, element.nome)">
                            <mat-icon>delete</mat-icon>Deletar
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</admin-page-wrapper>