import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  isLoading = false

  constructor(private readonly authService: AuthService) { }

  async onSignin() {
    this.isLoading = true;
    await this.authService.signin(this.loginForm.value);
    this.isLoading = false;
  }

}
