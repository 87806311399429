import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import {tap} from 'rxjs/operators';

@Injectable()
export class SetTokenRequestInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const loggedUser = <any>this.auth.getLoggedUser()

    // console.log(loggedUser)
    const modifiedReq = request.clone({ 
      headers: request.headers.set('Authorization', `Bearer ${loggedUser.accessToken}`).set('Content-Type', `application/json`),
    });

    return next.handle(modifiedReq).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
        return;
        }
        this.router.navigate(['login']);
      }
    }));
  }
}
