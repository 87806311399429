<admin-page-wrapper>
    <div class="admin-registration-form">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="admin-registration-form__form-container">
                <div class="admin-registration-form__form-column">
                    <div class="admin-registration-form__form-field-group">
                        <div class="image-container">
                            <img *ngIf="imageBase64 != undefined" [src]="imageBase64" alt="Imagem selecionada">
                            <img *ngIf="imageBase64 == undefined" [src]="form.get('imageLink').value"
                                alt="Selecione sua Imagem">
                            <button type="button" (click)="uploadImage()" class="btn-upload">Escolher Arquivo</button>
                        </div>
                    </div>
                    <div class="admin-registration-form__form-field-group">
                        <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                            <mat-label>Nome</mat-label>
                            <input matInput placeholder="Nome" required formControlName="nome">
                        </mat-form-field>
                    </div>
                    <div class="admin-registration-form__form-field-group">
                        <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                            <mat-label>Data início</mat-label>
                            <input matInput required formControlName="dataInicio" type="datetime-local">
                        </mat-form-field>

                    </div>
                    <div class="admin-registration-form__form-field-group">
                        <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                            <mat-label>Data fim?</mat-label>
                            <input matInput formControlName="dataFim" type="datetime-local">
                        </mat-form-field>
                    </div>
                    <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                        <mat-label for="numero">Ordem:</mat-label>
                        <input type="number" matInput rows="5" formControlName="ordem" required id="numero"
                            [(ngModel)]="numeroInserido" required>
                    </mat-form-field>
                    <div class="admin-registration-form__form-field admin-registration-form__form-field-radio-button">
                        <label id="radio-btn-perfil">O Banner conterá rota?</label>
                        <mat-radio-group required color="primary" aria-labelledby="radio-btn-perfil"
                            formControlName="isRoute">
                            <mat-radio-button [value]="true">Sim</mat-radio-button>
                            <mat-radio-button [value]="false">Não</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="admin-registration-form__form-field-group">
                        <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                          <mat-label>{{ form.controls.isRoute.value ? "Rota" : "Link" }}</mat-label>
                          
                          <ng-container *ngIf="form.controls.isRoute.value; else linkInput">
                            <mat-select required formControlName="route">
                              <mat-option *ngFor="let rota of listaDeRotas" [value]="rota.routeName">
                                {{ rota.name }}
                              </mat-option>
                            </mat-select>
                          </ng-container>
                      
                          <ng-template #linkInput>
                            <input matInput required [placeholder]="form.controls.isRoute.value ? 'Rota' : 'Link'" formControlName="route">
                          </ng-template>
                        </mat-form-field>
                      </div>
                      
                    <div class="admin-registration-form__form-field-group">
                        <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                            <mat-label>Feature Flag?:</mat-label>
                            <input matInput placeholder="Feature Flag" formControlName="featureFlag">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="admin-registration-form__form-actions">
                <button mat-flat-button color="primary" class="button-action" type="submit" [disabled]="registering">
                    <mat-spinner *ngIf="registering" style="display: inline-block;" [diameter]="24"
                        mode="indeterminate"></mat-spinner>
                    {{ idBanner ? 'Atualizar' : 'Cadastrar'}}
                </button>
                <button mat-stroked-button color="secondary" class="button-action" type="button" (click)="resetForm()"
                    [disabled]="registering">
                    <mat-icon>replay</mat-icon>
                    Limpar
                </button>
            </div>
        </form>
    </div>
</admin-page-wrapper>