<admin-page-wrapper>
    <div class="table__empty-state" style="display: flex; align-items: center; justify-content: center;" *ngIf="brindes.length === 0 && loaded"> 
        <h4>Não há registro aqui 🙁</h4>
    </div>

    <div style="width: 100%;" class="table__container" *ngIf="brindes.length > 0">
        <table style="width: 100%" mat-table [dataSource]="brindes">

            <ng-container matColumnDef="imagem">
                <th class="colunas_brinde" style="padding-left: 1px;"mat-header-cell *matHeaderCellDef><strong>Imagem</strong></th>
                <td class="colunas_brinde" style=" padding-left: 1px; padding-right: 2px; width: 110px;" *matCellDef="let element">
                    <img style="width: 96px; height: 70px;" [src]="element.imageLink" alt="Imagem do brinde">
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th class="colunas_brinde" mat-header-cell *matHeaderCellDef><strong>Nome do brinde</strong></th>
                <td class="colunas_brinde" mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.nome}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="descricao">
                <th class="colunas_brinde" mat-header-cell *matHeaderCellDef><strong>Descrição</strong></th>
                <td class="colunas_brinde" mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.descricao}}
                    </div>
                </td>
            </ng-container>

            <ng-container class="colunas_brinde" matColumnDef="quantidadeEstoque">
                <th class="colunas_brinde" mat-header-cell *matHeaderCellDef><strong>Estoque</strong></th>
                <td class="colunas_brinde" mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.quantidadeEstoque}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="takoletas">
                <th class="colunas_brinde" mat-header-cell *matHeaderCellDef><strong>Takoletas</strong></th>
                <td class="colunas_brinde" mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.takoletasResgate}}
                    </div>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="ordem">
                <th class="colunas_brinde" mat-header-cell *matHeaderCellDef><strong>Ordem</strong></th>
                <td class="colunas_brinde" mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.ordem}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="ativo">
                <th class="colunas_brinde" mat-header-cell *matHeaderCellDef><strong>Ativo</strong></th>
                <td class="colunas_brinde" mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.ativo ? 'Ativo' : 'Inativo'}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th style="padding-right: 1px; width: 50px;" class="colunas_brinde" mat-header-cell *matHeaderCellDef>Ações</th>
                <td style="padding-right: 1px;" mat-cell *matCellDef="let element">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon> 
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                            [routerLink]="['/admin/app-brindes-edit/', element._Id]"><mat-icon>edit</mat-icon>Editar
                        </button>
                        <button mat-menu-item (click)="openDeleteConfirmationModal(element._Id, element.nome)">
                            <mat-icon>delete</mat-icon>Deletar
                        </button>
                    </mat-menu>
                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</admin-page-wrapper>