import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushControllerService {
  constructor(private readonly http: HttpClient) {}

  async agendarPush(push): Promise<any> {
    try {
      const url =
        environment.apiBaseUrl + '/PushNotification/ScheduleNotification';

      const response = await this.http.post(url, push).toPromise<any>();

      if (response.success) {
        return response?.result || [];
      } else {
        return response?.result.message;
      }
    } catch (error) {
      return error;
    }
  }
}
