<admin-page-wrapper>
  <div class="admin-registration-form">
    <img src="/assets/images/logo-takao.png" alt="Logo Takao" class="logo" />
    <form #userFormDirective="ngForm" [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="admin-registration-form__form-container">
        <div class="admin-registration-form__form-column">
          <div class="admin-registration-form__form-field-group">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>CPF</mat-label>
              <input matInput placeholder="000.000.000-00" mask="000.000.000-00" required formControlName="cpf">
            </mat-form-field>

            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Data de Nascimento</mat-label>
              <input matInput placeholder="DD/MM/YYYY" [dropSpecialCharacters]="false" mask="00/00/0000" required formControlName="dataNascimento">
            </mat-form-field>
          </div>

          <div class="admin-registration-form__form-field-group">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Nome Completo</mat-label>
              <input matInput placeholder="Digite seu nome" required formControlName="nome">
            </mat-form-field>
          </div>

          <div class="admin-registration-form__form-field-group">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Celular (o mesmo número do WhatsApp)</mat-label>
              <input matInput [dropSpecialCharacters]="false" placeholder="(00) 00000-0000"
                mask="(00) 0000-0000||(00) 00000-0000" required formControlName="telefone">
            </mat-form-field>

            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Confirme o número</mat-label>
              <input matInput [dropSpecialCharacters]="false" placeholder="(00) 00000-0000"
                mask="(00) 0000-0000||(00) 00000-0000" required formControlName="telefoneConfirmacao">
              <mat-error *ngIf="userForm.controls.telefoneConfirmacao.invalid">
                Os telefones parecem diferente!
              </mat-error>
            </mat-form-field>
          </div>

          <div class="admin-registration-form__form-field-group" formGroupName="endereco">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>CEP</mat-label>
              <input matInput placeholder="00000-000" mask="00000-000" formControlName="cep" required>
              <mat-spinner *ngIf="gettingAddress" mode="indeterminate" color="primary" diameter="24"
                matSuffix></mat-spinner>
            </mat-form-field>

            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Endereço</mat-label>
              <input matInput formControlName="logradouro">
            </mat-form-field>
          </div>

          <div class="admin-registration-form__form-field-group" formGroupName="endereco">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Número</mat-label>
              <input matInput formControlName="numero">
            </mat-form-field>

            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="bairro">
            </mat-form-field>
          </div>

          <div class="admin-registration-form__form-field-group" formGroupName="endereco">
            <mat-form-field class="admin-registration-form__form-field" appearance="fill">
              <mat-label>Complemento</mat-label>
              <input matInput formControlName="complemento">
            </mat-form-field>
          </div>
        </div>

        <div class="admin-registration-form__form-column">
          <div class="admin-registration-form__form-field admin-registration-form__form-field-radio-button">
            <label id="radio-btn-perfil">Área / Perfil</label>
            <mat-radio-group required  color="primary" aria-labelledby="radio-btn-perfil" formControlName="perfil" >
              <mat-radio-button *ngFor="let option of areaAtuacao.allOptions" [value]="option.value" >
                {{option.label}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <mat-form-field class="admin-registration-form__form-field" appearance="fill">
            <mat-label>Nome da Mecânica</mat-label>
            <input matInput formControlName="nomeMecanica">
          </mat-form-field>

          <mat-form-field class="admin-registration-form__form-field" appearance="fill">
            <mat-label>CNPJ</mat-label>
            <input matInput placeholder="00.000.000/0000-00" mask="00.000.000/0000-00" formControlName="cnpj">
          </mat-form-field>

          <mat-form-field class="admin-registration-form__form-field" appearance="fill">
            <mat-label>Senha</mat-label>
            <input matInput placeholder="Digite seu nome" [readonly]="true" [disabled]="true" type="text" required
              formControlName="senha">
            <button mat-icon-button matSuffix (click)="generatePassword()" type="button">
              <mat-icon>refresh</mat-icon>
            </button>
            <mat-hint>A senha é automaticamente gerada!</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="admin-registration-form__form-actions">
        <button mat-flat-button color="primary" class="button-action" type="submit" [disabled]="registering">
          <mat-spinner *ngIf="registering"  style="display: inline-block;" [diameter]="24" mode="indeterminate"></mat-spinner>
          Cadastrar
        </button>
        <button mat-stroked-button color="secondary" class="button-action" type="button" (click)="resetForm()"
          [disabled]="registering">
          <mat-icon>replay</mat-icon>
          Limpar
        </button>
      </div>
    </form>
  </div>
</admin-page-wrapper>
