<admin-page-wrapper>
    <div class="table__empty-state" style="display: flex; align-items: center; justify-content: center;" *ngIf="flags.length === 0 && loaded">
        <h4>Não há registro aqui 🙁</h4>
    </div>
    <div style="width: 100%;" class="table__container" *ngIf="flags.length > 0">
        <table style="width: 100%" mat-table [dataSource]="flags">
            <ng-container matColumnDef="icone">
                <th mat-header-cell *matHeaderCellDef><strong>Icone da Flag</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1" style="margin-left: 20px;">
                        <mat-icon>{{element.icone}}</mat-icon>
                    </div>
                </td> 
            </ng-container>
            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef><strong>Nome da flag</strong></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row items-center gap-1">
                        {{element.nome}}
                    </div>
                </td> 
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openDeleteConfirmationModal(element._Id, element.nome)">
                            <mat-icon>delete</mat-icon>Deletar
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</admin-page-wrapper>