import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { QuizService, Pergunta } from 'src/app/services/quiz.service';
import { FirebaseQuizz } from '../remote-control/remote-control.component';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizComponent implements OnInit {
  quiz$: Observable<Pergunta>;
  constructor(
    public quizService: QuizService,
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) {
    this.quiz$ = quizService.activeQuestion;
  }

  eventData = <any>{};

  ngDestroy() {
    this.quizService.unsubscribe();
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        let selectedEvent = params.event;

        if (!selectedEvent) {
          this.router.navigateByUrl('admin/events');
        } else {
          this.quizService.SetSelectedService(selectedEvent)
        }

        this.quizService.subscribe(selectedEvent);
        this.firestore
          .collection('feiras')
          .doc<FirebaseQuizz>(selectedEvent)
          .valueChanges()
          .subscribe(async (data) => {
            this.eventData = {};
            this.eventData.idEvento = data.id_evento
            this.eventData.codigoEvento = data.codigo_evento
            this.eventData.nome = data.nome_evento
            this.eventData.previsaoFim = data.previsao_fim
            this.eventData.perguntaAtual = data.pergunta_atual;
            this.router.navigateByUrl(data.ir_para_tela + '?event=' + selectedEvent);
          });
      }
      );
  }

  answerLetter(idAnswer: number): String {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet = alpha.map((x) => String.fromCharCode(x));
    return alphabet[idAnswer];
  }

  trackByFn(index, item) {
    return item.id;
  }
}
