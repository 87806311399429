<admin-page-wrapper>  
    <div class="table__empty-state" *ngIf="events.length == 0 && loaded">
      <h4>Não há registro aqui 🙁</h4>
    </div>
  
    <!-- <mat-form-field appearance="outline" *ngIf="events.length > 0" class="search-field">
      <mat-label>Pesquisar</mat-label>
      <input matInput placeholder="Pesquisar por nome do evento" [(ngModel)]="search">
    </mat-form-field>
   -->
    <div class="table__container" *ngIf="events.length > 0">
      <table mat-table [dataSource]="events">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef><strong>Nome do evento</strong></th>
          <td mat-cell *matCellDef="let element">
            <div class="flex flex-row items-center gap-1">
              {{element.nome}}         
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="codigoEvento">
          <th mat-header-cell *matHeaderCellDef><strong>Código Evento</strong></th>
          <td mat-cell *matCellDef="let element">
            <div class="flex flex-row items-center gap-1">
              {{element.codigoEvento}}         
            </div>
          </td>
        </ng-container>

          <ng-container matColumnDef="startEvent">
            <th mat-header-cell *matHeaderCellDef><strong>Data Início</strong></th>
            <td mat-cell *matCellDef="let element">              
                  {{formatDate(element.previsaoInicio)}}      
            </td>
          </ng-container>
  

          <ng-container matColumnDef="endEvent">
            <th mat-header-cell *matHeaderCellDef><strong>Data Fim</strong></th>
            <td mat-cell *matCellDef="let element">              
                {{formatDate(element.previsaoFim)}}      
            </td>
          </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef><strong>Status</strong></th>
            <td mat-cell *matCellDef="let element">{{element.eventoAtivo ? 'Ativo': 'Inativo'}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">  
            <button mat-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
          </button>
            
          <mat-menu #menu="matMenu">
            <button mat-menu-item  [routerLink]="['/admin//event-edit', element.idEvento]" ><mat-icon>edit</mat-icon>Editar</button>
            <!-- <button mat-menu-item> <mat-icon>delete</mat-icon>Deletar</button>             -->
          </mat-menu>           
          </td>
          
       </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </admin-page-wrapper>
  