import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteConfirmComponent } from 'src/app/components/delete-confirm/delete-confirm/delete-confirm.component';
import { FeatFlagService } from 'src/app/services/feat-flag.service';

@Component({
  selector: 'app-listar-feat-flag',
  templateUrl: './listar-feat-flag.component.html',
  styleUrls: ['./listar-feat-flag.component.scss'],
})
export class ListarFeatFlagComponent implements OnInit {
  loaded: boolean = true;
  flags = [];

  displayedColumns: string[] = ['icone', 'nome', 'actions'];

  constructor(
    private service: FeatFlagService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.getAll();
  }

  async getAll() {
    try {
      const flagsResponse = await this.service.getAllFlags();

      this.flags = flagsResponse || [];
    } catch (error) {
      console.error('Erro ao obter featFlags:', error);
    }
  }

  openDeleteConfirmationModal(element, nome): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '350px',
      data: { elementData: element && nome },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.service.deleteById(element, nome);
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([
                this.router.navigateByUrl('/admin/app-listar-feat-flag'),
              ]);
            });
        } catch (error) {}
      }
    });
  }
}
