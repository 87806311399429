<admin-page-wrapper>
    <div class="admin-registration-form">
      <form #formDirective="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="admin-registration-form__form-container">
          <div class="admin-registration-form__form-column">


            <div class="admin-registration-form__form-field-group">
              <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                <mat-label>Nome do evento</mat-label>
                <input matInput placeholder="Digite seu nome" required formControlName="nome">
              </mat-form-field>
            </div>

            <div class="admin-registration-form__form-field-group">
                <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Data/hora início do evento</mat-label>
                  <input matInput  required formControlName="dataInicio" type="datetime-local">
                </mat-form-field>

                <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Data/hora fim do evento</mat-label>
                  <input matInput  required formControlName="dataFim" type="datetime-local">
                </mat-form-field>
              </div>

              <div class="admin-registration-form__form-field-group" >
                <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Descrição do evento (botão)</mat-label>
                  <textarea matInput rows="5" formControlName="textoApp" required></textarea>
                </mat-form-field>
              </div>

              <div  formGroupName="endereco" class="admin-registration-form__form-field-group">
                <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Logradouro</mat-label>
                  <input matInput formControlName="logradouro" type="text">
                </mat-form-field>

                <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Bairro</mat-label>
                  <input matInput formControlName="bairro" type="text">
                </mat-form-field>

                <div style="display: flex;">
                  <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                    <mat-label>Número</mat-label>
                    <input matInput formControlName="numero" type="text">
                  </mat-form-field>
                  <div style="width: 10px;"></div>
                  <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                    <mat-label>CEP</mat-label>
                    <input matInput formControlName="cep" type="text">
                  </mat-form-field>
                </div>


              </div>

              <div class="admin-registration-form__form-field-group">
                <div style="display: flex;width: 60%;"  formGroupName="endereco">
                  <mat-form-field style="padding-right: 10px;" class="admin-registration-form__form-field" appearance="fill">
                    <mat-label>Cidade</mat-label>
                    <input matInput formControlName="cidade" type="text">
                  </mat-form-field>

                  <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                    <mat-label>Estado</mat-label>
                    <input maxlength="2" matInput formControlName="estado" type="text">
                  </mat-form-field>
                </div>


                <mat-form-field formGroupName="endereco" class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Complemento</mat-label>
                  <input matInput formControlName="complemento" type="text">
                </mat-form-field>
              </div>

              <div class="admin-registration-form__form-field-group">
                <mat-form-field class="admin-registration-form__form-field" appearance="fill">
                  <mat-label>Link Incrição</mat-label>
                  <input matInput  required formControlName="linkInscricao" type="text">
                </mat-form-field>
              </div>

              <div class="admin-registration-form__form-field admin-registration-form__form-field-radio-button" *ngIf="!idEvento">
                <label id="radio-btn-perfil">Evento possui QUIZ</label>
                <mat-radio-group required  color="primary" aria-labelledby="radio-btn-perfil" formControlName="possuiQuiz" >
                  <mat-radio-button [value]="1" >Sim</mat-radio-button>
                  <mat-radio-button [value]="0" >Não</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="admin-registration-form__form-field admin-registration-form__form-field-radio-button">
                <label id="radio-btn-perfil">Status</label>
                <mat-radio-group required  color="primary" aria-labelledby="radio-btn-perfil" formControlName="status" >
                    <mat-radio-button [value]="1" >Ativo</mat-radio-button>
                    <mat-radio-button [value]="0" >Inativo</mat-radio-button>
                </mat-radio-group>
              </div>

          </div>

        </div>

        <div class="admin-registration-form__form-actions">
          <button mat-flat-button color="primary" class="button-action" type="submit" [disabled]="registering">
            <mat-spinner *ngIf="registering" style="display: inline-block;" [diameter]="24" mode="indeterminate"></mat-spinner>
            {{ idEvento ? 'Atualizar' : 'Cadastrar'}}
          </button>
          <button mat-stroked-button color="secondary" class="button-action" type="button" (click)="resetForm()"
            [disabled]="registering">
            <mat-icon>replay</mat-icon>
            Limpar
          </button>
        </div>
      </form>
    </div>
  </admin-page-wrapper>
