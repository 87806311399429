import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  addMinutes,
  differenceInSeconds,
  isAfter,
  isValid,
  parseISO,
} from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { QuizService } from 'src/app/services/quiz.service';

enum UltimoStatus {
  RODADA_AGENDADA = 'rodada_agendada',
  RODADA_INICIADA = 'rodada_iniciada',
  RODADA_FINALIZADA = 'rodada_finalizada',
}

export interface FirebaseQuizz {
  exibir_vencedores: boolean;
  horario_do_quiz: string;
  id_evento: string;
  nome_evento?: string;
  previsao_fim: string,
  codigo_evento?: string;
  pergunta_atual: number;
  exibir_contador: boolean;
  ultimo_status: UltimoStatus;
  iniciado: boolean;
  token_evento: string;
  ir_para_tela: string;
}

interface EncerrarRodadaParams {
  podium?: boolean;
  should_finish?: boolean;
}

@Component({
  selector: 'app-remote-control',
  templateUrl: './remote-control.component.html',
  styleUrls: ['./remote-control.component.scss'],
})
export class RemoteControlComponent implements OnInit, OnDestroy {
  channel = new BroadcastChannel('app-routes');

  fair_quizzes$: Observable<any>;
  _sub: Subscription;

  noEvents = false;

  firebaseEvents = [];

  startDate = null;
  selectedEvent = null
  /**
   *
   */
  isControlEnabled = false;

  /**
   *
   */
  isBooted = false;

  /**
   *
   */
  isQuizzScheduled = false;

  /**
   *
   */
  isQuizzInProgress = false;

  /**
   *
   */
  isQuizzFinished = false;

  currentQuestion = 0;
  perguntas = [];
  isLoading = false;

  constructor(
    private readonly firestore: AngularFirestore,
    public readonly quizService: QuizService,
    public eventService: EventService
  ) { }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  async loadFirestore() {
    this.isLoading = true;

    this._sub = this.firestore
      .collection('feiras')
      .doc<FirebaseQuizz>(this.selectedEvent)
      .valueChanges()
      .subscribe(async (data) => {

        console.log(this.selectedEvent)
        let selectedEvent = this.firebaseEvents.filter(evt => evt.idEvento == this.selectedEvent)[0]

        if (selectedEvent) {
          data = <any>{...data, ...{
            id_evento: this.selectedEvent,
            codigo_evento: selectedEvent.codigoEvento,
            nome_evento: selectedEvent.nome,
            previsao_fim: selectedEvent.previsaoFim,
            texto_botao: selectedEvent.textoBotao
          }}

          //Reforça dados do evento na firestore caso não exista
          this.firestore.collection('feiras').ref.doc(this.selectedEvent).set(data, {merge: true})
        }

        this.isBooted = data.iniciado === true;
        const {
          ultimo_status,
          horario_do_quiz,
          pergunta_atual,
          token_evento: token,
          id_evento: idEvento,
        } = data;
        this.perguntas = await this.quizService.fetchQuestions({
          token,
          idEvento,
        });
        this.currentQuestion = pergunta_atual || 0;
        this.isQuizzScheduled = ultimo_status === UltimoStatus.RODADA_AGENDADA;
        this.isQuizzInProgress = ultimo_status === UltimoStatus.RODADA_INICIADA;
        this.isQuizzFinished = ultimo_status === UltimoStatus.RODADA_FINALIZADA;
        const startDate = parseISO(horario_do_quiz);

        // start count down
        this.startDate = isAfter(startDate, new Date()) ? startDate : null;
        if (this.isLoading) this.isLoading = false;
      });

  }
  
  async ngOnInit() {
    this.noEvents = false;
    this.isLoading = true;
    this.eventService.listAll().then(events => {
      console.log(events)
      this.firebaseEvents = events.filter(evt => {
        return (evt.eventoAtivo == true)
      })

      if (this.firebaseEvents.length == 0) {
        this.noEvents = true;
        this.isLoading = false;
      } else {
        if (!this.selectedEvent) {
          this.quizService.SetSelectedService(this.firebaseEvents[0].idEvento)
          this.selectedEvent = this.firebaseEvents[0].idEvento
        } else {}
        this.loadFirestore();
      }
    })
    // this.firestore.collection('feiras').ref.get().then((data) => {
    //   this.firebaseEvents = data.docs.map((evt => {
    //     return evt.id;
    //   }))
    // })

  }

  setSelectedEvent(evt) {
    console.log(evt)
    this.selectedEvent = evt
    this._sub.unsubscribe();
    this.quizService.SetSelectedService(evt)
    this.loadFirestore()
  }

  _confirm(fn: Function, ...args: any[]): void {
    fn.call(this, ...args);

    // Se precisar habilitar confirmação
    // comente o codigo acima
    // e descomente o codigo abaixo.

    // if (window.confirm('Você deseja continuar?')) {
    //   fn.call(this, ...args);
    // }
  }

  get questionButtonText(): string {
    if (this.currentQuestion === 0) return 'Iniciar Perguntas';
    if (this.currentQuestion + 1 > this.perguntas.length)
      return 'Encerrar rodada';
    const total = this.perguntas.length;
    const current = this.currentQuestion;
    return `Próxima pergunta (${current} / ${total})`;
  }

  get isQuizEnded(): boolean {
    return this.questionButtonText === 'Encerrar rodada';
  }

  private async _getQuiz(): Promise<Record<string, any>> {
    return this.firestore
      .collection('feiras')
      .doc(this.selectedEvent)
      .get()
      .toPromise()
      .then((r) => r.data());
  }

  async _novaRodada(minutes?: number): Promise<void> {
    this.isLoading = true;
    const { horario_do_quiz: startTimeProp } = await this._getQuiz();
    let startTime =
      !!startTimeProp && isValid(parseISO(startTimeProp))
        ? parseISO(startTimeProp)
        : new Date();
    const isRightNow = typeof minutes === 'undefined';
    let dynamicData = {};

    if (differenceInSeconds(startTime, new Date()) <= 0) startTime = new Date();

    if (isRightNow) {
      await this.quizService.createNewQuiz({ should_finish: false, idEvent: this.selectedEvent });
      dynamicData = { ...dynamicData, ir_para_tela: 'quiz' };
    }

    await this.firestore
      .collection('feiras')
      .doc(this.selectedEvent)
      .update({
        ...dynamicData,
        pergunta_atual: isRightNow ? 1 : 0,
        horario_do_quiz: isRightNow
          ? new Date().toISOString()
          : addMinutes(startTime, minutes || 0).toISOString(),
        ultimo_status: isRightNow
          ? UltimoStatus.RODADA_INICIADA
          : UltimoStatus.RODADA_AGENDADA,
        exibir_contador: true,
      });
    this.isLoading = false;
  }

  async _encerrarRodada(params?: EncerrarRodadaParams): Promise<void> {
    this.isLoading = true;
    const should_finish = params?.should_finish ?? true;
    if (should_finish) await this.quizService.finishCurrentQuiz();
    await this.firestore
      .collection('feiras')
      .doc(this.selectedEvent)
      .update({
        ultimo_status: UltimoStatus.RODADA_FINALIZADA,
        horario_do_quiz: new Date().toISOString(),
        pergunta_atual: 0,
        exibir_vencedores: false,
        ir_para_tela: params?.podium === true ? 'podium' : 'instructions',
      });
    this.isLoading = false;
  }

  async _proximaPergunta(): Promise<void> {
    this.isLoading = true;
    this.channel.postMessage('quiz');

    if (this.isQuizEnded)
      return await this._encerrarRodada({ podium: true, should_finish: false });

    const quizz = await this.firestore
      .collection('feiras')
      .doc<FirebaseQuizz>(this.selectedEvent)
      .get()
      .toPromise()
      .then((r) => r.data());

    if (['string', 'number'].includes(typeof quizz.pergunta_atual)) {
      const nextQuestion = Number(quizz.pergunta_atual) + 1;
      await this.firestore.collection('feiras').doc(this.selectedEvent).update({
        pergunta_atual: nextQuestion,
      });
    }
    this.isLoading = false;
  }

  async _iniciar(): Promise<void> {
    this.isLoading = true;
    await this.firestore.collection('feiras').doc(this.selectedEvent).update({
      iniciado: true,
    });
    this.channel.postMessage('instructions');
    //this._novaRodada(10);
    this._novaRodada(1);
  }

  async _resetar(): Promise<void> {
    await this._encerrarRodada();
    this.firestore.collection('feiras').doc(this.selectedEvent).update({
      iniciado: false,
      ir_para_tela: '',
      exibir_contador: false,
    });
    window.location.reload;
  }

  async _irParaTela(tela: string): Promise<void> {
    this.firestore.collection('feiras').doc(this.selectedEvent).update({
      ir_para_tela: tela,
    });
  }
}
