<admin-page-wrapper>
  <div class="takoletasForm">
    <h2 class="form-title">Controle de Takoletas</h2>

    <div class="takoletasForm__forms-container">
      <form [formGroup]="formulario" (ngSubmit)="creditarTakoletas()" class="form-column">
        <h3 class = "titulo">Adicionar Takoletas:</h3>
        <div class="takoletasForm__form-field-group">
          <mat-form-field class="takoletasForm__form-field" appearance="fill">
            <mat-label>Código do Aplicador</mat-label>
            <input
              matInput
              placeholder="Digite o código do aplicador"
              formControlName="codAplicador"
              required
            />
          </mat-form-field>
          <mat-form-field class="takoletasForm__form-field" appearance="fill">
            <mat-label>Motivo</mat-label>
            <input matInput placeholder="Digite o motivo" required formControlName="motivo" />
          </mat-form-field>
          <mat-form-field class="takoletasForm__form-field" appearance="fill">
            <mat-label>Quantidade de Takoletas</mat-label>
            <input matInput type="number" placeholder="Digite a quantidade" required formControlName="takoletas" />
          </mat-form-field>
        </div>

        <div class="takoletasForm__form-actions">
          <mat-checkbox color="primary" formControlName="adicionarParaTodos">
            Adicionar para Todos os Aplicadores
          </mat-checkbox>
          <button mat-flat-button color="primary" class="button-action" type="submit" [disabled]="registering">
            <mat-spinner *ngIf="registering" [diameter]="24" mode="indeterminate"></mat-spinner>
            Adicionar Takoletas
          </button>
        </div>
      </form>
    </div>
  </div>
</admin-page-wrapper>
