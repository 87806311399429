import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrindesService } from 'src/app/services/brindes.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

NgModule({
  imports: [BrowserModule, FormsModule],
  providers: [],
});
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
@Component({
  selector: 'app-brindes-order',
  templateUrl: './brindes-order.component.html',
  styleUrls: ['./brindes-order.component.scss'],
})
export class BrindesOrderComponent implements OnInit {
  selectedStatus: string = '';
  pedidos: any[] = [];
  result: any;
  codRastreio: string = '';

  constructor(private brindesService: BrindesService) {}

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.brindesService.getListPedidos().subscribe(
      (data) => {
        this.result = data;
        this.pedidos = this.result.result;
      },
      (error) => {
        console.error('Erro ao obter a lista de pedidos:', error);
      }
    );
  }

  alterarStatus(pedido) {
    this.brindesService.alterarStatus(pedido).subscribe(
      (data) => {
        let codRastreio = this.codRastreio;
        pedido.codRastreio = codRastreio;
        if (pedido.status === 'Enviado') {
          this.brindesService.sendCodRastreio(pedido).subscribe(
            (data) => {
              this.result = data;
              this.getAll();
            },
            (error) => {
              console.error('Erro ao enviar codigo:', error);
            }
          );
        } else {
          this.result = data;
          this.getAll();
        }
      },
      (error) => {
        console.error('Erro ao alterar status:', error);
      }
    );
  }

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
}
